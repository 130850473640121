import React from "react";

import Intro from "../../img/intro.mp4";
import background2 from "./../../img/background2.jpg";

const New = () => {
    return (
        <>
            <div className="container my-5">
                <div  className="container features">
                    <div className="container featureMain bd-highlight py-5  px-0 ">
                        <div className="featureBoxFirst mx-3 my-3 pt-3">
                            <div className="featureIcon pt-4 d-flex justify-content-center align-items-center mb-3">
                                <svg
                                    preserveAspectRatio="none"
                                    data-bbox="0 0 512 512"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="60"
                                    viewBox="0 0 512 512"
                                    height="60"
                                    data-type="shape"
                                    role="presentation"
                                    aria-hidden="true"
                                >
                                    <g>
                                        <path
                                            d="M256 301c-24.814 0-45 20.186-45 45v36.707c14.145 5.043 29.145 8.293 45 8.293s30.855-3.25 45-8.293V346c0-24.814-20.186-45-45-45z"
                                            fill="#86C441"
                                            data-color="1"
                                        ></path>
                                        <path
                                            d="M271 226c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15z"
                                            fill="#86C441"
                                            data-color="1"
                                        ></path>
                                        <path
                                            d="M437 362c-15.26 0-29.436 4.634-41.294 12.495l-34.466-34.466C379.735 316.938 391 287.824 391 256c0-31.813-11.517-60.699-29.993-83.796l34.699-34.699C407.564 145.366 421.74 150 437 150c41.353 0 75-33.647 75-75S478.353 0 437 0s-75 33.647-75 75c0 15.26 4.634 29.436 12.495 41.294l-34.699 34.699C316.699 132.517 287.813 121 256 121s-60.699 11.517-83.796 29.993l-34.699-34.699C145.366 104.436 150 90.26 150 75c0-41.353-33.647-75-75-75S0 33.647 0 75s33.647 75 75 75c15.26 0 29.436-4.634 41.294-12.495l34.699 34.699C132.517 195.301 121 224.187 121 256c0 31.824 11.265 60.938 29.76 84.029l-34.466 34.466C104.436 366.634 90.26 362 75 362c-41.353 0-75 33.647-75 75s33.647 75 75 75 75-33.647 75-75c0-15.26-4.634-29.436-12.495-41.294l34.448-34.448c2.959 2.368 5.892 4.772 9.047 6.888V346c0-41.353 33.647-75 75-75-24.814 0-45-20.186-45-45s20.186-45 45-45 45 20.186 45 45-20.186 45-45 45c41.353 0 75 33.647 75 75v22.147c3.155-2.117 6.088-4.521 9.047-6.888l34.448 34.448C366.634 407.564 362 421.74 362 437c0 41.353 33.647 75 75 75s75-33.647 75-75-33.647-75-75-75z"
                                            fill="#86C441"
                                            data-color="1"
                                        ></path>
                                    </g>
                                </svg>
                            </div>
                            <div className="featureTitle text-center mt-4">
                                {" "}
                                1000+ Farmer's <br />
                                Network
                            </div>
                        </div>

                        <div className="featureBox mx-3 my-3 pt-3">
                            <div className="featureIcon pt-4 d-flex justify-content-center align-items-center mb-3">
                                <svg
                                    preserveAspectRatio="none"
                                    data-bbox="0 0 512.01 512.01"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="60"
                                    viewBox="0 0 512.01 512.01"
                                    height="60"
                                    data-type="shape"
                                    role="presentation"
                                    aria-hidden="true"
                                >
                                    <g>
                                        <path
                                            d="M403.792 150.511h-48.308V482.01h-36.178v-269.3H192.69v269.3h-36.178v-207.1H29.897v207.1H0v30h512v-30h-29.901V150.511h-48.307V92.284h78.218V0H403.792z"
                                            fill="#86C441"
                                            data-color="1"
                                        ></path>
                                    </g>
                                </svg>
                            </div>
                            <div className="featureTitle text-center mt-4">
                                STATE OF THE ART
                                <br /> FACILITY
                            </div>
                        </div>

                        <div className="featureBox mx-3 my-3 pt-3">
                            <div className="featureIcon pt-4 d-flex justify-content-center align-items-center mb-3">
                                <svg
                                    preserveAspectRatio="none"
                                    data-bbox="0 0 512 512"
                                    viewBox="0 0 512 512"
                                    width="60"
                                    height="60"
                                    xmlns="http://www.w3.org/2000/svg"
                                    data-type="shape"
                                    role="presentation"
                                    aria-hidden="true"
                                >
                                    <g>
                                        <path
                                            d="M255.8 482c-71 0-137.6-34-179.8-90h44v-30H32v90h31v-29.1c48 55.6 118 89.1 192.8 89.1 21.6 0 43.5-2.7 64.2-8l-7.5-29.1c-18.3 4.7-37.7 7.1-56.7 7.1z"
                                            fill="#86C441"
                                            data-color="1"
                                        ></path>
                                        <path
                                            d="M255.8 30c71 0 137.6 34 179.8 90H391v30h88V60h-31v29C400 33.5 330.5 0 255.8 0c-21.6 0-44 2.7-64.8 8l7.5 29.1c18.3-4.7 38.2-7.1 57.3-7.1z"
                                            fill="#86C441"
                                            data-color="1"
                                        ></path>
                                        <path
                                            d="M512 256c0-21.5-2.7-43.2-8-64l-29.1 7.5c4.7 18.3 7.1 37.5 7.1 56.5 0 71-34 136.8-90 179v-44h-30v88h90v-31h-28.9c55.5-48 88.9-117.3 88.9-192z"
                                            fill="#86C441"
                                            data-color="1"
                                        ></path>
                                        <path
                                            d="M30 256c0-71 34-137.6 90-179.8V121h30V33H60v31h29.2C33.5 112 0 181.2 0 256c0 21.5 2.7 43.2 8 64l29.1-7.5C32.4 294.2 30 275 30 256z"
                                            fill="#86C441"
                                            data-color="1"
                                        ></path>
                                        <path
                                            d="M256 96.8c-87.9 0-159.2 71.3-159.2 159.2S168.1 415.2 256 415.2 415.2 343.9 415.2 256 343.9 96.8 256 96.8zm-88.4 110c-6.2-14.5-8.6-31.7-13.4-47-.7-2.4 1.6-4.4 3.9-3.3 9.6 5.3 22.9 9.8 35 11.1 19.7 2.2 40 2.8 57.8 9.7 17 6.5 39.6 20.6 50.3 38.2-13.1 1.5-26.3 3.7-38.7 7.9-4.2-3-8.7-5.4-13.6-7.2-7.8-2.7-17.3-3.7-25.5-5.9 8.7 4 17.8 7.9 26 12.1 2.1 1.1 4 2.3 5.9 3.7-6.8 2.9-13.1 6.3-19.2 10.8-7.1 5.3-13.3 11.7-18.5 19.6-2.7-.5-5.3-1.2-7.3-2.4-20-8.3-34.4-27.7-42.7-47.3zm211.4-2.4c1.9-.2 3.6 2 2.7 4.5-6.2 19.6-9.3 41.8-17.4 60.7-10.9 25.3-29.3 46.2-55 57-6.6 2.6-13.4 4.5-20.5 5.8-20.1 3.8-42 3.9-60.3 11.4-1.2.6-2.4.8-3.4.8-12 0 2-38.6 33.7-55.2 6.5-3.5 13.3-6.5 20.1-9.7 1.3-.6.8-2.3-.4-2.3h-.4c-5.8 1.7-11.6 3.4-17.2 5.5-21.2 7.7-38.3 20.1-47.2 41.3-7.8 18.2-11.3 40.7-5.6 60 .9 2.7-2.6 4.7-4.6 2.8-1.5-1.5-2-3.9-2.4-5.8-2.3-8.7-2.4-17.9-2.5-26.8-.7-21.9 2.6-43.9 9.8-64.6 3.9-11.5 8.7-22.5 15.6-32 4.2-5.9 9.4-11.5 15.6-16 6.7-5 13.7-8.6 21-11.4 2.4-.9 5-1.9 7.5-2.5 11.6-3.4 23.7-5.2 36-6.6 25.1-2.9 50.7-4.5 73.2-16.6.6-.2 1.2-.3 1.7-.3z"
                                            fill="#86C441"
                                            data-color="1"
                                        ></path>
                                    </g>
                                </svg>
                            </div>
                            <div className="featureTitle text-center mt-4">
                                {" "}
                                Efficient supply <br />
                                chain
                            </div>
                        </div>

                        <div className="featureBox mx-3 my-3 pt-3">
                            <div className="featureIcon pt-4 d-flex justify-content-center align-items-center mb-3">
                                <svg
                                    preserveAspectRatio="xMidYMid meet"
                                    data-bbox="0 0 64.566 58"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 64.566 58"
                                    height="58"
                                    width="65"
                                    data-type="color"
                                    role="presentation"
                                    aria-hidden="true"
                                    aria-labelledby="svgcid-s7gxhanu9ha"
                                >
                                    <title id="svgcid-s7gxhanu9ha"></title>
                                    <g>
                                        <path
                                            d="M13.202 52.14c1.65 0 2.991 1.313 2.991 2.93 0 1.616-1.34 2.93-2.991 2.93-1.65 0-2.991-1.314-2.991-2.93 0-1.617 1.34-2.93 2.991-2.93zm14.543 0c1.65 0 2.99 1.313 2.99 2.93 0 1.616-1.34 2.93-2.99 2.93-1.65 0-2.991-1.314-2.991-2.93 0-1.617 1.34-2.93 2.99-2.93zm14.646 0c1.652 0 2.99 1.311 2.99 2.93 0 1.618-1.338 2.93-2.99 2.93-1.652 0-2.991-1.312-2.991-2.93 0-1.619 1.339-2.93 2.99-2.93zm14.646 0c1.652 0 2.99 1.311 2.99 2.93 0 1.618-1.338 2.93-2.99 2.93-1.652 0-2.991-1.312-2.991-2.93 0-1.619 1.339-2.93 2.99-2.93zM6.085 44.156c0 1.617-1.34 2.93-2.99 2.93-1.651 0-2.992-1.313-2.992-2.93 0-1.617 1.341-2.93 2.991-2.93 1.65-.102 2.991 1.212 2.991 2.93zm11.655-2.93c1.65 0 2.991 1.212 2.991 2.93 0 1.617-1.34 2.93-2.99 2.93-1.651 0-2.992-1.313-2.992-2.93 0-1.617 1.34-2.93 2.991-2.93zm17.534 2.93c0 1.617-1.34 2.93-2.991 2.93-1.65 0-2.991-1.313-2.991-2.93 0-1.617 1.34-2.93 2.991-2.93 1.65-.102 2.991 1.212 2.991 2.93zm26.301-2.93c1.65 0 2.991 1.212 2.991 2.93 0 1.617-1.34 2.93-2.991 2.93-1.65 0-2.991-1.313-2.991-2.93 0-1.617 1.34-2.93 2.991-2.93zm-11.655 2.93c0 1.617-1.34 2.93-2.991 2.93-1.65 0-2.991-1.313-2.991-2.93 0-1.617 1.34-2.93 2.991-2.93 1.65-.102 2.991 1.212 2.991 2.93zM31.355 0c.31 0 .515.101.722.303a18.462 18.462 0 0 1 5.57 13.237c0 4.85-1.857 9.397-5.26 12.934v5.962l3.712-1.516c.207-4.75 2.166-9.195 5.57-12.53 3.504-3.432 8.173-5.342 13.066-5.451l.445-.005c.31 0 .516.1.722.303.207.202.31.404.31.707 0 4.951-1.96 9.7-5.57 13.237-3.61 3.537-8.251 5.457-13.305 5.457l-4.95 2.122v1.414h32.179v2.021H0v-2.02h30.323V34.76l-4.95-2.122C14.955 32.537 6.498 24.25 6.498 13.944c0-.303.103-.505.31-.707.102-.202.412-.303.721-.303 2.579 0 5.054.505 7.323 1.414 2.27.91 4.435 2.324 6.189 4.042 3.506 3.335 5.466 7.882 5.57 12.53l3.712 1.516v-5.962c-3.403-3.537-5.26-8.084-5.26-12.934 0-4.951 1.96-9.7 5.57-13.237A.944.944 0 0 1 31.355 0z"
                                            fill="#86C441"
                                            data-color="1"
                                        ></path>
                                    </g>
                                </svg>
                            </div>
                            <div className="featureTitle text-center mt-4">
                                SMART HARVESTING <br />
                                METHOD
                            </div>
                        </div>
                        <div className="featureBoxLast mx-3 my-3 pt-3">
                            <div className="featureIcon pt-4 d-flex justify-content-center align-items-center mb-3 rounded-end">
                                <svg
                                    preserveAspectRatio="xMidYMid meet"
                                    data-bbox="0 0 58 58"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 58 58"
                                    height="58"
                                    width="58"
                                    data-type="color"
                                    role="presentation"
                                    aria-hidden="true"
                                    aria-labelledby="svgcid-ak4hl5-aqkfap"
                                >
                                    <title id="svgcid-ak4hl5-aqkfap"></title>
                                    <g>
                                        <path
                                            d="M1.705 17.774c3.175 0 5.594 2.612 5.594 5.66v14.453c0 .456.18.892.499 1.215l7.3 7.358c.664.67 1.743.67 2.408 0a1.727 1.727 0 0 0 0-2.43l-4.063-4.095a3.925 3.925 0 0 1-.053-5.464c1.506-1.577 4.047-1.547 5.582 0l5.52 5.565a9.114 9.114 0 0 1 2.637 6.418v9.829A1.71 1.71 0 0 1 25.425 58H12.652a1.71 1.71 0 0 1-1.703-1.717v-2.968L.499 42.78A1.724 1.724 0 0 1 0 41.566V19.492a1.71 1.71 0 0 1 1.704-1.718zm54.591 0c.941 0 1.704.769 1.704 1.718v22.074c0 .456-.18.892-.499 1.215l-10.45 10.534v2.968A1.71 1.71 0 0 1 45.348 58H32.575a1.71 1.71 0 0 1-1.704-1.717v-9.829c0-2.407.949-4.716 2.637-6.418l5.52-5.565c1.535-1.547 4.076-1.577 5.582 0a3.925 3.925 0 0 1-.053 5.464l-4.063 4.096a1.727 1.727 0 0 0 0 2.429c.665.67 1.744.67 2.409 0l7.299-7.358c.32-.322.5-.76.5-1.215V23.434c0-3.048 2.418-5.66 5.593-5.66zM48.831 0c.93 0 1.685.76 1.685 1.697 0 11.325-8.654 20.65-19.646 21.609l.001 11.487c0 .934-.838 1.69-1.871 1.69s-1.871-.756-1.871-1.69V23.306C16.14 22.347 7.485 13.022 7.485 1.696A1.69 1.69 0 0 1 9.167 0C18.075 0 25.735 5.485 29 13.28 32.265 5.484 39.924 0 48.83 0z"
                                            fill="#86C441"
                                            data-color="1"
                                        ></path>
                                    </g>
                                </svg>
                            </div>
                            <div className="featureTitle text-center mt-4">
                                {" "}
                                SUSTAINABLY PRODUCED
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <div
                className="container bg-light"
                style={{
                    backgroundImage: `url('${background2}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    marginTop: "10rem",
                    marginBottom: "10rem"
                }}
            >
                <div className="py-5">
                    <center>
                        {/* <video
                            src={Intro}
                            width="900"
                            height="500"
                            controls
                            style={{ border: "6px solid #ffd8b1" }}
                        >
                            </video> */}
                        <iframe width="420" height="315"
                            src="https://www.youtube.com/embed/baIX0RlRULY">
                        </iframe>
                    </center>
                </div>
            </div>
        </>
    );
};

export default New;
