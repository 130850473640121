import React from "react";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import pin from "../../img/pineaaple-min.jpg";
import pinn1 from "../../img/pinapple.jpg";

import pin1 from "../../img/pineapple/pin1.jfif";
import pin2 from "../../img/pineapple/pin2.jfif";
import pin3 from "../../img/pineapple/pin3.jpeg";

import mango from "../../img/mango.svg";
import CarouselContainer from "./CarouselContainer";
const Pineapple = () => {
  return (
    <>

      <div className="banana-section py-5">
        <div className="banana-img py-5">
          <div className="container  bg-light py-3 px-3">
            <img src={pin} alt="err" className="img-fluid" />
          </div>
        </div>

        <div className="container bg-light py-4">
          <h2 className="text-uppercase pt-1" style={{ color: "green" }}>Pineapple</h2>
          <hr style={{ color: "green", height: "2px" }} />

          <div className="row">
            <div className="col-md-6">
              <div className="procard1 px-5">
                <img src={pinn1} alt="err" className="img-fluid w-75" />
              </div>
            </div>

            <div className="col-md-6">
              <ul>
                <li className="mt-3">Pineapple (Ananas comosus) is an important fruit 
                of India. Pineapple is cultivated in an area of 89 thousand ha and total 
                production is 1,415.00 thousand tons. It is abundantly grown in almost 
                entire North East region, West Bengal, Kerala, Karnataka, Bihar, Goa and
                 Maharashtra states.
                </li>
              </ul>
            </div>
          </div>

          <div className="row py-5">
            <div className="col-md-6">
              <div className="procard2 px-5 pb-4">

                <b6 className="text-justify">
                  Exporter and supplier of Pineapple. Our range of Pineapple is available with following features and specifications:
                </b6>
                <br /><br />
                <b3 className="py-5"><b>Details:</b></b3>

                <ul>
                <li className="mt-3"><b style={{ color: "green" }}>HS CODE :</b> 0804 30 00</li>
                  <li className="mt-3"><b>Variety:</b> Kew/ Giant Kew/ Queen/ Mauritius/ jaldhup/ lakhat.
                  </li>
                  <li className="mt-3"><b>Packging Size:</b> 10kg.
                  </li>
                  <li className="mt-3"><b>Weight:</b> 2kg.
                  </li>
                  <li className="mt-3"><b>Availability:</b> July to December.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <hr />

          <div className="container bg-light py-4">
            <div className="row">
              <div className="col-md-4 pb-4">
                <div className="procard1 border">
                  <img src={pin1}
                    alt="err"
                    className="img-fluid" />
                </div>
              </div>
              <div className="col-md-4 pb-4">
                <div className="procard1 border">
                  <img src={pin2}
                    alt="err"
                    className="img-fluid" />
                </div>
              </div>
              <div className="col-md-4 pb-4">
                <div className="procard1 border">
                  <img src={pin3}
                    alt="err"
                    className="img-fluid" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* <div>
        <Navbar />
        <div className="banana">
          <div>
            <img src={pin} alt="err" className="img-fluid mt-5" />
          </div>
          <div class="prodHeader my-5">
            <h2 class="prodh  text-center " id="">
              Pineapple
            </h2>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5">
            <div className="procard1">
              <img src={pin1} alt="err" className="img-fluid" />
            </div>
            <div className="procard2 p-5">
              <h2 class="text-left mb-4" id="">
                Pineapple
              </h2>
              <p className="text-justify">
                India is the land of Mangos & It is a National fruit of the
                country. Mangos are grown in varied places in India giving
                diversity in taste. Mango is the "king of fruits" & it has many
                famous varieties like ALPHONSO, KESAR, NEELAM, BANAGANAPLLI,
                etc.
              </p>
            </div>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5 ">
            <div className="proCardInfo p-5">
            <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Variety : </strong> Alphonso, Kesar
                  </p>
                </div>
              </div>
              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Brix : </strong> 13 to 24
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Packaging : </strong>&nbsp;Corrugated Carton
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Average Weight : </strong> 180 to 300 Gm
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Shelf Life : </strong> Up to 10/12 days
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">No of Pieces : </strong> 12 Pcs, 6 Pcs
                  </p>
                </div>
              </div>
             
              
            </div>
            <div className="proCardInfo2">
                <img src="https://images.pexels.com/photos/947878/pexels-photo-947878.jpeg?cs=srgb&dl=pexels-alizee-marchand-947878.jpg&fm=jpg" alt="" className="img-fluid"/>
            </div>
          </div>
        </div>
        <CarouselContainer />
      </div> */}
      {/* <Footer /> */}
    </>
  );
};

export default Pineapple;
