import React, { useState } from "react";
import AllData from "./AllData";
function Product() {
  const [images, setImage] = useState(AllData);

  // const allItem = () => {
  //   const finalData = AllData.filter((value) => {
  //     return value;
  //   });
  //   setImage(finalData);
  // };

  const onlyPython = (categoryItem) => {
    const finalData = AllData.filter((value) => {
      return value.category == categoryItem;
    });

    setImage(finalData);
  };
  const onlyOffice = (categoryItem) => {
    const finalData = AllData.filter((value) => {
      return value.category == categoryItem;
    });

    setImage(finalData);
  };
  const onlyReact = (categoryItem) => {
    const finalData = AllData.filter((value) => {
      return value.category == categoryItem;
    });
    setImage(finalData);
  };
  const onlyPlastic = (categoryItem) => {
    const finalData = AllData.filter((value) => {
      return value.category == categoryItem;
    });
    setImage(finalData);
  };

  return (
    <>
      <div className="product">
        <div className="container my-3 mx-auto productPage">
          <div className="d-flex justify-content-center align-items-center">
            
            <div className="mx-3">
              <button
                className="btn  btnProduct"
                onClick={() => onlyPython("Python")}
              >
                Fruits & Vegetables
              </button>
            </div>
            <div className="mx-3">
              <button
                className="btn  btnProduct"
                onClick={() => onlyOffice("Office")}
              >
                Processed Foods
              </button>
            </div>
            <div className="mx-3">
              <button
                className="btn  btnProduct"
                onClick={() => onlyReact("React")}
              >
                Beverges
              </button>
            </div>
            <div className="mx-3">
              <button
                className="btn  btnProduct"
                onClick={() => onlyPlastic("Plastic")}
              >
                Plastic
              </button>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
            {images.map((value) => {
              return (
                <div className="col mt-3">
                  <img src={value.image} className="img-fluid prodImg" alt="image" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Product;
