import React from 'react'
import { NavLink } from "react-router-dom";

const OnionCategory = () => {
    return (
        <div>
            <div className="row py-3">
                <div className="col-md-2 px-2">
                    
                        <NavLink
                            to="/Redonion"
                            className="nav-link active"
                            style={{
                                fontSize: "15px",
                                padding: "4px",
                            }}
                        >
                            <button className="btn btnProduct">Red Onion</button>
                        </NavLink>
                </div>
                
                <div className="col-md-2 px-2">
                        <NavLink
                            to="/Whiteonion"
                            className="nav-link"
                            style={{
                                fontSize: "15px",
                                padding: "4px",
                            }}
                        >
                            <button className="btn btnProduct">White Onion</button>
                        </NavLink>
                </div>

            </div>
        </div>
    )
}

export default OnionCategory