import React from 'react'
import { NavLink } from "react-router-dom";

const MangoCategory = () => {
    return (
        <div>
            <div className="row pb-5">
                <div className="col-md-2 px-2">
                    <NavLink
                        to="/Alphansomango"
                        className="nav-link active"
                        style={{

                            fontSize: "15px",
                            padding: "4px",
                        }}
                    >
                        <button className="btn btnProduct">
                            Alphanso Mango
                        </button>
                    </NavLink>
                </div>

                <div className="col-md-2 px-2">
                    <NavLink
                        to="/kesarmango"
                        className="nav-link"
                        style={{

                            fontSize: "15px",
                            padding: "4px",
                        }}
                    >
                        <button className="btn btnProduct">
                        Kesar Mango
                    </button>
                </NavLink>

            </div>

        </div>
        </div >
    )
}

export default MangoCategory