import React from "react";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import banana from "../../img/banProduct.jpg";
import bananaa from "../../img/banana.jpg";
import mango from "../../img/mango.svg";
import CarouselContainer from "./CarouselContainer";
import Onion from "../../img/onion/onion.png";
import Onion1 from "../../img/onion/onion1.jpg";
import Oni1 from "../../img/onion/oni1.jpg";
import Oni2 from "../../img/onion/oni2.jpg";
import Oni3 from "../../img/onion/oni5.jpg";
import Oni4 from "../../img/onion/oni6.jpg";

import OnionCategory from "./OnionCategory/OnionCategory";
const RedOnion = () => {
  return (
    <>

      <div className="mango-section py-5">
        <div className="mango-img py-5">
          <div className="container  bg-light py-3 px-3">
            <img src={Onion} alt="err" className="img-fluid" />
          </div>
        </div>

        <div className="container bg-light py-4">
          <h2 className="text-uppercase pt-1" style={{ color: "green" }}>ONION</h2>
          <hr style={{ color: "green", height: "2px" }} />

          <div>
            <OnionCategory />
          </div>

          <div>
            <h2 className="text-uppercase pt-1" style={{ color: "green" }}>Red Onion</h2>
            <hr style={{ color: "green", height: "2px" }} />

            <div className="row">
              <div className="col-md-6">
                <div className="procard2 px-2 pb-4">
                  <b6 className="text-justify">
                    We are involved in exporting high quality Red Onion has long shelf life and high pungency.
                    Our Red Onions are highly nutritious & rich in minerals, calcium, iron, etc.
                    that make their demand consistent in the global markets. We are Directly
                    Associated with onion Growers.
                  </b6>

                  <ul>
                  <li className="mt-3"><b style={{ color: "green" }}>HS CODE :</b> 0703 10 10</li>
                    <li className="mt-3"><b>Variety:</b> Red onion
                    </li>
                    <li className="mt-3"><b>Packaging:</b> Mesh bags-1kg, 2kg, 5kg, 10kg, 20kg, 50kg.
                    </li>
                    <li className="mt-3"><b>Colour:</b> Dark Red, Light Red, White.
                    </li>
                    <li className="mt-3"><b>Size:</b> 30 to 45 mm, 45 to 60 mm, 60mm & above.
                    </li>
                    <li className="mt-3"><b>Availability:</b> Throughout year.
                    </li>
                  </ul>

                  <h4><b>Storage Requirements for long transit period.</b></h4>

                  <ul>
                    <li className="mt-3"><b>Temperature:</b> 0'C.
                    </li>
                    <li className="mt-3"><b>Relative Humidity:</b> 65-70%.
                    </li>
                    <li className="mt-3"><b>Storage Period:</b> 3-5 months.
                    </li>
                    <li className="mt-3"><b>Capacity:</b> 1-40 feet container -28.00 mt, 1-20 feet container - 12.5-13 mt.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 pt-5 px-5">
                <div className="procard1 px-5">
                  <img src={Onion1}
                    alt="err"
                    className="img-fluid border w-75" />
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="container bg-light py-4">
            <div className="row">
              <div className="col-md-3 pb-4">
                <div className="procard1 border">
                  <img src={Oni1}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={Oni2}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={Oni3}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={Oni4}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <Navbar />
        <div className="banana">
          <div>
            <img src={banana} alt="err" className="img-fluid" />
          </div>
          <div class="prodHeader my-5">
            <h2 class="prodh  text-center " id="">
              Banana
            </h2>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5">
            <div className="procard1">
              <img src={bananaa} alt="err" className="img-fluid" />
            </div>
            <div className="procard2 p-5">
              <h2 class="text-left mb-4" id="">
                Banana
              </h2>
              <p className="text-justify">
                India is the land of Mangos & It is a National fruit of the
                country. Mangos are grown in varied places in India giving
                diversity in taste. Mango is the "king of fruits" & it has many
                famous varieties like ALPHONSO, KESAR, NEELAM, BANAGANAPLLI,
                etc.
              </p>
            </div>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5 ">
            <div className="proCardInfo p-5">
            <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Variety : </strong> Alphonso, Kesar
                  </p>
                </div>
              </div>
              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Brix : </strong> 13 to 24
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Packaging : </strong>&nbsp;Corrugated Carton
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Average Weight : </strong> 180 to 300 Gm
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Shelf Life : </strong> Up to 10/12 days
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">No of Pieces : </strong> 12 Pcs, 6 Pcs
                  </p>
                </div>
              </div>
             
              
            </div>
            <div className="proCardInfo2">
                <img src="https://images.pexels.com/photos/2872767/pexels-photo-2872767.jpeg?cs=srgb&dl=pexels-aleksandar-pasaric-2872767.jpg&fm=jpg" alt="" className="img-fluid"/>
            </div>
          </div>
        </div>
        <CarouselContainer />
      </div> */}
      {/* <Footer /> */}
    </>
  );
};

export default RedOnion;
