import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Home from "./Pages/Home/Home";
import { Routes, Route, HashRouter } from "react-router-dom";
import Contact from "./Pages/Contact/Contact";
import About from "./Pages/About/About";
import Productss from "./Pages/Products/Productss";
import Gallery from "./Pages/Gallery/Gallery";
import Banana from "./Products/Banana/Banana";
import Pineapple from "./Products/Pineapple/Pineapple";
import Coconut from "./Products/coconut/Coconut";
import Grapes from "./Products/Grapes/Grapes";
import AlphansoMango from "./Products/Mango/AlphansoMango";
import KesarMango from "./Products/Mango/KesarMango";
import RedOnion from "./Products/Onion/RedOnion";
import WhiteOnion from "./Products/Onion/WhiteOnion";
import Sugar from "./Products/Sugar/Sugar";
import Maida from "./Products/Maida/Maida";
import Jaggery from "./Products/Jaggery/Jaggery";
import Turmeric from "./Products/Turmeric/Turmeric";
import Water from "./Products/Water/Water";


function App() {
  return (
    <>

    <Navbar />
    {/* <HashRouter> */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route  exact path="/contact" element={<Contact />} />
        <Route  exact path="/about" element={<About />} />
        <Route  exact path="/products" element={<Productss />} />
        <Route  exact path="/gallery" element={<Gallery />} />
        <Route  exact path="/banana" element={<Banana />} />
        <Route  exact path="/grapes" element={<Grapes />} />
        <Route  exact path="/coconut" element={<Coconut />} />
        <Route  exact path="/pineapple" element={<Pineapple/>} />
        <Route  exact path="/Alphansomango" element={<AlphansoMango/>} />
        <Route  exact path="/kesarmango" element={<KesarMango/>} />
        <Route  exact path="/Redonion" element={<RedOnion/>} />
        <Route  exact path="/Whiteonion" element={<WhiteOnion/>} />
        <Route  exact path="/sugar" element={<Sugar/>} />
        <Route  exact path="/maida" element={<Maida/>} />
        <Route  exact path="/jaggery" element={<Jaggery />} />
        <Route  exact path="/turmeric" element={<Turmeric />} />
        <Route  exact path="/water" element={<Water />} />
      </Routes>
    {/* </HashRouter> */}
      <Footer />
    </>
  );
}

export default App;
