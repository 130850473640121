import React from "react";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import coconut from "../../img/coconut-min.jpg";
import bananaa from "../../img/banana.jpg";
import mango from "../../img/mango.svg";
import banner from "../../img/coconut/banner.jpg";

import coc1 from "../../img/coconutt.jpg"
import coc2 from "../../img/coconut/coconut1.jpg"
import coc4 from "../../img/coconut/coc4.jpeg"
import coc5 from "../../img/coconut/coc5.jpeg"
import coc3 from "../../img/coconut/banner.jpg"

import CarouselContainer from "./CarouselContainer";
const Coconut = () => {
  return (
    <>

      <div className="banana-section py-5">
        <div className="banana-img py-5">
          <div className="container  bg-light py-3 px-3">
            <img src={coconut} alt="err" className="img-fluid" />
          </div>
        </div>

        <div className="container bg-light py-4">
          <h2 className="text-uppercase pt-1" style={{ color: "green" }}>coconut</h2>
          <hr style={{ color: "green", height: "2px" }} />

          <div className="row">

            <div className="col-md-6">
              <div className="procard1 px-5">
                <img src={coc1} alt="err" className="img-fluid w-75" />
              </div>
            </div>

            <div className="col-md-6">
              <ul>
                <li className="mt-3">A coconut is the edible fruit of the coconut palm 
                (Cocos nucifera), a tree of the palm family. Coconut flesh is high in fat and 
                can be dried or eaten fresh or processed into coconut milk or coconut oil.
                </li>
                <li className="mt-3">Traditional areas of coconut in India are the states of Kerala, Tamilnadu, Karnataka, Andhra Pradesh, Orissa, Goa, West Bengal, Pondicherry, Maharashtra and Islands of Lakshadweep and Andaman and Nicobar.</li>
              </ul>
            </div>

            <div className="row py-5">
              <div className="col-md-6">
                <div className="procard2 px-5 pb-4">

                  <b6 className="text-justify">
                    Exporter and supplier of Coconut. Our range of Coconut is available with following features and specifications:
                  </b6>
                  <br /><br />
                  <b3 className="py-5"><b>Details:</b></b3>

                  <ul>
                  <li className="mt-3"><b style={{ color: "green" }}>HS CODE :</b> 0801 19 10</li>
                    <li className="mt-3"><b>Variety:</b> Kalpa Raksha/Kalpa Sree/Kalpa Jyoth/Chandra Sankara
                    </li>
                    <li className="mt-3"><b>Availability:</b> Throughtout the year.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 px-5">
                <div className="procard1 px-5">
                  <img src={coc2}
                    style={{width:"25rem", height:"18rem", borderRadius:"20px"}}
                    alt="err"
                    className="img-fluid border" />
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="container bg-light py-4">
            <div className="row">
              <div className="col-md-4 pb-4">
                <div className="procard1 border">
                  <img src={coc4}
                    style={{height:"16rem"}}
                    alt="err"
                    className="img-fluid" />
                </div>
              </div>
              <div className="col-md-4 pb-4">
                <div className="procard1 border">
                  <img src={coc5}
                    style={{height:"16rem"}}
                    alt="err"
                    className="img-fluid" />
                </div>
              </div>
              <div className="col-md-4 pb-4">
                <div className="procard1 border">
                  <img src={coc3}
                    style={{height:"16rem"}}
                    alt="err"
                    className="img-fluid" 
                    />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* <div>
        <Navbar />
        <div className="banana">
          <div>
            <img src={coconut} alt="err" className="img-fluid mt-5" />
          </div>
          <div class="prodHeader my-5">
            <h2 class="prodh  text-center " id="">
              Coconut
            </h2>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5">
            <div className="procard1">
              <img src={coc1} alt="err" className="w-100" height="350"/>
            </div>
            <div className="procard2 p-5">
              <h2 class="text-left mb-4" id="">
                Coconut
              </h2>
              <p className="text-justify">
                India is the land of Mangos & It is a National fruit of the
                country. Mangos are grown in varied places in India giving
                diversity in taste. Mango is the "king of fruits" & it has many
                famous varieties like ALPHONSO, KESAR, NEELAM, BANAGANAPLLI,
                etc.
              </p>
            </div>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5 ">
            <div className="proCardInfo p-5">
            <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Variety : </strong> Alphonso, Kesar
                  </p>
                </div>
              </div>
              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Brix : </strong> 13 to 24
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Packaging : </strong>&nbsp;Corrugated Carton
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Average Weight : </strong> 180 to 300 Gm
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Shelf Life : </strong> Up to 10/12 days
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">No of Pieces : </strong> 12 Pcs, 6 Pcs
                  </p>
                </div>
              </div>
             
              
            </div>
            <div className="proCardInfo2">
                <img src={coc2} alt="" className="" width="500" height="500"/>
            </div>
          </div>
        </div>
        <CarouselContainer />
      </div> */}
      {/* <Footer /> */}
    </>
  );
};

export default Coconut;
