import React from "react";
import pl from "../../img/pl.jpeg";
import team2 from "../../img/team/team-2.jpg";
import rk from "../../img/rk.jpeg";
import main from "../../img/main.JPG";
const Team = () => {
  return (
    <div className="container">
      <section id="team" className="team">
        <div className="container" data-aos="fade-up">
          <div class="prodHeader mb-5">
            <h2 class="prodh mb-5 text-center" id="">
              OUR TEAM
            </h2>
          </div>
          <section id="cta" className="cta">
            <div className="container">
              <div className="row g-5">
                <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
                  <h3>SHAILESH DESHMUKH</h3>
                  <p>
                    {" "}
                    Our company proprietor is professionally
                    a food technologist & always ready to
                    assist any request regarding the quality
                    and purity of products.
                  </p>
                  <a
                    className="cta-btn align-self-start"
                    href="#"
                    style={{ fontSize: '25px' }}
                  >
                    Proprietor
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
                  <div className="img">
                    <img
                      src={main}
                      alt
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="row gy-5">
            <div className="col-xl-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay={200}>
              <div className="team-member">
                <div className="member-img">
                  <img src={pl} style={{height: "30rem", textAlign:"center"}} className="img-fluid" alt />
                </div>
                <div className="member-info">
                  <div className="social">
                    <a href><i className="bi bi-twitter" /></a>
                    <a href><i className="bi bi-facebook" /></a>
                    <a href><i className="bi bi-instagram" /></a>
                    <a href><i className="bi bi-linkedin" /></a>
                  </div>
                  <h4>Pravin Londhe</h4>
                  <span>General Manager</span>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay={400}>
              <div className="team-member">
                <div className="member-img">
                  <img src={team2} style={{height: "30rem", textAlign:"center"}} className="img-fluid" alt />
                </div>
                <div className="member-info">
                  <div className="social">
                    <a href><i className="bi bi-twitter" /></a>
                    <a href><i className="bi bi-facebook" /></a>
                    <a href><i className="bi bi-instagram" /></a>
                    <a href><i className="bi bi-linkedin" /></a>
                  </div>
                  <h4>Shital Deshmukh</h4>
                  <span>Financial Officials</span>
                </div>
              </div>
            </div> */}
            <div className="col-xl-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay={600}>
              <div className="team-member">
                <div className="member-img">
                  <img src={rk} style={{height: "30rem", textAlign:"center"}} className="img-fluid" alt />
                </div>
                <div className="member-info">
                  <div className="social">
                    <a href><i className="bi bi-twitter" /></a>
                    <a href><i className="bi bi-facebook" /></a>
                    <a href><i className="bi bi-instagram" /></a>
                    <a href><i className="bi bi-linkedin" /></a>
                  </div>
                  <h4>Rushikesh Koli</h4>
                  <span>Technology Officials</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Team;
