import React from 'react'
import OnionCategory from "./OnionCategory/OnionCategory";
import Onion from "../../img/onion/onion.png";

import Oni1 from "../../img/onion/oni1.jpg";
import Oni2 from "../../img/onion/oni3.jpg";
import Oni3 from "../../img/onion/oni4.jpg";

const WhiteOnion = () => {
  return (
    <div>
      <div className="mango-section py-5">
        <div className="mango-img py-5">
          <div className="container  bg-light py-3 px-3">
            <img src={Onion} alt="err" className="img-fluid" />
          </div>
        </div>

        <div className="container bg-light py-4">
          <h2 className="text-uppercase pt-1" style={{ color: "green" }}>Onion</h2>
          <hr style={{ color: "green", height: "2px" }} />

          <div>
            <OnionCategory />
          </div>

          <div>
            <h2 className="text-uppercase pt-1" style={{ color: "green" }}>White Onion</h2>
            <hr style={{ color: "green", height: "2px" }} />

            <div className="row">
              <div className="col-md-6">
                <div className="procard2 px-2 pb-4">
                  <b6 className="text-justify">
                    Exporter and supplier of White Onion. Our range of White Onion is available with following features and specifications:
                  </b6>

                  <ul>
                  <li className="mt-3"><b style={{ color: "green" }}>HS CODE :</b> 0703 10 10</li>
                    <li className="mt-3"><b>Variety:</b> N2-4-1, N-257-9-1.
                    </li>
                    <li className="mt-3"><b>Packaging:</b> Mesh bags - 5kg, 10kg, 25kg.
                    </li>
                    <li className="mt-3"><b>Details:</b> White.
                    </li>
                    <li className="mt-3"><b>Size:</b> 25 to 30 mm, 40 to 60 mm, 60 to 80 mm.
                    </li>
                    <li className="mt-3"><b>Availability:</b> Throughout year.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 pt-1 px-5">
                <div className="procard1 px-5">
                  <img src={Oni3}
                    alt="err"
                    className="img-fluid border w-100" />
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="container bg-light py-4">
            <div className="row">
              <div className="col-md-3 pb-4">
                <div className="procard1 border">
                  <img src={Oni1}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={Oni2}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={Oni3}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhiteOnion