import React, { useState } from "react";
import AllData from "../../Components/Product/AllData";
import Product from "../../Components/Product/Product";
function Gallery() {
  const [images, setImage] = useState(AllData);

  return (
    <>

    <Product />
    
      {/* <div className="product">
        
        <h2 className="text-center">Gallery</h2>

        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
            {images.map((value) => {
              return (
                <div className="col mt-3">
                  <img src={value.image} className="img-fluid prodImg border" alt="image" />
                </div>
              );
            })}
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Gallery;
