import React from "react";
import New from "../../Components/Features/New";
import { ToastContainer } from "react-toastify";

import Banner from "../../Components/Banner/Banner";
// import Product from "../../Components/Product/Product";
import Ourprocess from "../../Components/OurProcess/Ourprocess";
// import Ourclient from "../../Components/Clients/Ourclient";
import About from "../../Components/About/About";

const Home = () => {
    return (
        <div className="pb-5">
            {/* <Navbar /> */}
            <Banner />
            <About />

            {/* <Product /> */}
            <Ourprocess />
            <New />
            {/* <Ourclient /> */}
            <ToastContainer />
        </div>
    );
};

export default Home;
