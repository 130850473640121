import React from "react";
import banana from "../../img/turmericBanner.jpg";
import bananaa from "../../img/turmeric powder.jpg";

import T1 from "../../img/turmeric/t1.jpeg";
import T2 from "../../img/turmeric/t2.jpeg";
import T3 from "../../img/turmeric/t3.jpeg";
import mango from "../../img/mango.svg";
import CarouselContainer from "./CarouselContainer";
const Turmeric = () => {
  return (
    <>

      <div className="grapes-section py-5">
        <div className="banana-img py-5">
          <div className="container  bg-light py-3 px-3">
            <img src={banana}
              alt="err"
              className="img-fluid" />
          </div>
        </div>

        <div className="container bg-light py-4">
          <h2 className="text-uppercase pt-1" style={{ color: "green" }}>Turmeric</h2>
          <hr style={{ color: "green", height: "2px" }} />

          <div className="row">
            <div className="col-md-6">
              <ul>
                <li className="mt-3"> Turmeric comes from the root of the Curcuma longa plant and has a tough brown skin and a deep orange flesh.
                </li>
                <li className="mt-3"> Turmeric has a peppery, warm and bitter flavor and a mild fragrance slightly reminiscent of orange and ginger. 
                </li>
                <li className="mt-3"> Turmeric is an excellent source of both iron and mangenese. It is also a good source of vitamin B6, dietary fiber and potassium.
                </li>
                <li className="mt-3"><b style={{ color: "green" }}>HS CODE :</b> 0910 30 30</li>
                <li className="mt-3"><b>Variety:</b> Salem/Nizam
                </li>
                <li className="mt-3"><b>Packaging Size:</b> 1kg, 5kg
                </li>
                <li className="mt-3"><b>Packaging:</b> Packets
                </li>
                <li className="mt-3"><b>Form:</b> Dried
                </li>
                <li className="mt-3"><b>Packaging Type:</b> Cartons
                </li>
              </ul>
            </div>

            <div className="col-md-6 pt-5 px-5">
              <div className="procard1 px-5">
                <img src={bananaa}
                  style={{height:"22rem"}}
                  alt="err"
                  className="img-fluid border w-100" />
              </div>
            </div>
          </div>

          <hr />

          <div className="container bg-light py-4">
            <div className="row">
              <div className="col-md-3 pb-4">
                <div className="procard1 border">
                  <img src={T1}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={T2}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={T3}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <Navbar />
        <div className="banana">
          <div>
            <img src={banana} alt="err" className="img-fluid" />
          </div>
          <div class="prodHeader my-5">
            <h2 class="prodh  text-center " id="">
              Banana
            </h2>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5">
            <div className="procard1">
              <img src={bananaa} alt="err" className="img-fluid" />
            </div>
            <div className="procard2 p-5">
              <h2 class="text-left mb-4" id="">
                Banana
              </h2>
              <p className="text-justify">
                India is the land of Mangos & It is a National fruit of the
                country. Mangos are grown in varied places in India giving
                diversity in taste. Mango is the "king of fruits" & it has many
                famous varieties like ALPHONSO, KESAR, NEELAM, BANAGANAPLLI,
                etc.
              </p>
            </div>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5 ">
            <div className="proCardInfo p-5">
            <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Variety : </strong> Alphonso, Kesar
                  </p>
                </div>
              </div>
              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Brix : </strong> 13 to 24
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Packaging : </strong>&nbsp;Corrugated Carton
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Average Weight : </strong> 180 to 300 Gm
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Shelf Life : </strong> Up to 10/12 days
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">No of Pieces : </strong> 12 Pcs, 6 Pcs
                  </p>
                </div>
              </div>
             
              
            </div>
            <div className="proCardInfo2">
                <img src="https://images.pexels.com/photos/2872767/pexels-photo-2872767.jpeg?cs=srgb&dl=pexels-aleksandar-pasaric-2872767.jpg&fm=jpg" alt="" className="img-fluid"/>
            </div>
          </div>
        </div>
        <CarouselContainer />
      </div>
      <Footer /> */}
    </>
  );
};

export default Turmeric;
