
import banana from '../../img/banana.jpg';
import grapes from '../../img/greps.jpg';
import coconut from '../../img/coconutt.jpg'
import mango from '../../img/mango.jpg';
import onion from '../../img/onion.jpg';
import gul from '../../img/gulllll.jpg'
import sugar from '../../img/sugar.jpg';
import turmeric from '../../img/turmeric powder.jpg';
import maida from '../../img/maida/maida.jpg'
import water1 from '../../img/water4.jpg';
import water2 from '../../img/water6.jfif';
import pet from '../../img/petBottle.jpg'
import cap from '../../img/cap.jpg';
import water from '../../img/waterBottle.jpeg';
import preform from '../../img/preform.jpg'
const Data = [
    {
        id:1,
        image:gul,
        category:'Office'
    },
    {
        id:2,
        image:sugar,
        category:'Office'
    },
    {
        id:3,
        image:maida,
        category:'Office'
    },
    {
        id:4,
        image:banana,
        category:'Python'
    },
    {
        id:5,
        image:grapes,
        category:'Python'
    },
    {
        id:6,
        image:mango,
        category:'Python'
    },
    {
        id:7,
        image:coconut,
        category:'Python'
    },
    {
        id:8,
        image:onion,
        category:'Python'
    },
    {
        id:9,
        image:maida,
        category:'Office'
    },
    {
        id:10,
        image:water1,
        category:'React'
    },
    {
        id:11,
        image:water2,
        category:'React'
    },
    {
        id:12,
        image:water,
        category:'Plastic'
    },
    {
        id:13,
        image:cap,
        category:'Plastic'
    },
    {
        id:15,
        image:preform,
        category:'Plastic'
    },
    {
        id:14,
        image:pet,
        category:'React'
    },
];

export default Data;