import React, { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";
// import Footer from '../../Components/Footer/Footer';
// import Navbar from '../../Components/Navbar/Navbar';

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_pjg4e6o",
                "template_iuu461l",
                form.current,
                "user_RzK5JvmhlZhCIIILWmq4h"
            )
            .then(
                (result) => {
                    toast.success("message sent successfully");
                    console.log(result.text);
                },
                (error) => {
                    toast.danger("fill all fields");
                }
            );
    };
    return (
        <>
            {/* <Navbar /> */}
            <div>
                <section id="contact" className="contact">
                    <div className="container mt-5">
                        <div class="prodHeader mx-auto mb-5">
                            <h2 class=" prodh text-center" id="">
                                Contact Us
                            </h2>
                        </div>
                    </div>
                    <div className="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3814.282057679001!2d74.4210805146657!3d17.05885058827627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc16ddd81fc212b%3A0x6abac1399f039efb!2sJK%20Industries!5e0!3m2!1sen!2sin!4v1650970282362!5m2!1sen!2sin"
                            frameBorder={0}
                            allowFullScreen
                            style={{marginLeft:'auto',marginRight:'auto',width:'100%', border:"5px solid black", borderRadius:"20px"}}
                        />
                    </div>
                    {/* End Google Maps */}
                    <div className="container" style={{"margin-top":"5rem"}}>
                        <div className="row gy-5 gx-lg-5">
                            <div className="col-lg-4">
                                <div className="info">
                                    <h3>Get in touch</h3>

                                    <div className="info-item d-flex">
                                        <i className="bi bi-geo-alt flex-shrink-0" />
                                        <div>
                                            <h4>Location:</h4>
                                            <p>
                                                {" "}
                                                kirloskarwadi Rd, <br />
                                                Burli, Maharashtra 416308
                                                <br />
                                                India
                                            </p>
                                        </div>
                                    </div>
                                    {/* End Info Item */}
                                    <div className="info-item d-flex">
                                        <i className="bi bi-envelope flex-shrink-0" />
                                        <div>
                                            <h4>Email:</h4>
                                            <p>
                                            contact@dbagrofood.com
                                            </p>
                                        </div>
                                    </div>
                                    {/* End Info Item */}
                                    <div className="info-item d-flex">
                                        <i className="bi bi-phone flex-shrink-0" />
                                        <div>
                                            <h4>Call:</h4>
                                            <p>+91 77768 81009</p>
                                        </div>
                                    </div>
                                    {/* End Info Item */}
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <form
                                    ref={form}
                                    onSubmit={sendEmail}
                                    action="forms/contact.php"
                                    method="post"
                                    className="php-email-form"
                                >
                                    <div className="row gy-4">
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                placeholder="Your Name"
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6 ">
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                placeholder="Your Email"
                                                required
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="subject"
                                                placeholder="Subject"
                                                required
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <textarea
                                                className="form-control"
                                                name="message"
                                                rows={6}
                                                placeholder="Message"
                                                required
                                                defaultValue={""}
                                            />
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <div className="loading">
                                                Loading
                                            </div>
                                            <div className="error-message" />
                                            <div className="sent-message">
                                                Your message has been sent.
                                                Thank you!
                                            </div>
                                            <button type="submit">
                                                Send Message
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* End Contact Form */}
                        </div>
                    </div>
                </section>
            </div>
            {/* <Footer /> */}
        </>
    );
};

export default Contact;
