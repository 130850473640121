import React from 'react';
// import pineapple from '../../img/pineaaple-min.jpg'
// import grapes from '../../img/grapes-min.jpg'
// import plastic from '../../img/plastic.jpeg'
// import turmeric from '../../img/turmericBanner.jpg'
// import grow from '../../img/grow2.jpg'
// import mango from '../../img/mango-min.jpg'
import one from '../../img/one.jpg'
import two from '../../img/two.jpg'
import three from '../../img/three.jpg'
import four from '../../img/four.jpg'
import five from '../../img/five.jpg'
import six from '../../img/six.jpg'
import seven from '../../img/seven.png'

const Banner = () => {
  return (
    <>
      <div id="banner" className="banner">
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={seven} style={{width:"100%"}} className="img-fluid" alt="..." />
            </div>
            {/* <div className="carousel-item">
      <img src={two} className="img-fluid" alt="..." />
    </div> */}
            <div className="carousel-item">
              <img src={three}  className="img-fluid" alt="..." />
            </div>
            {/* <div className="carousel-item">
              <img src={four} className="img-fluid" alt="..." />
            </div> */}
            <div className="carousel-item">
              <img src={five} className="img-fluid" alt="..." />
            </div>
            {/* <div className="carousel-item">
              <img src={six} className="" width="100%" height="540px" alt="..." />
            </div> */}
            <div className="carousel-item">
              <img src={two} className="" width="100%" height="100%" alt="..." />
            </div>
            {/* <div className="carousel-item">
      <img src={grow} className="img-fluid" alt="..." />
    </div> */}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <div className="featureHead">
          <div class="prodHeader">
            <h2 class="prodh  text-center " id="">
              <span className="DB">DB</span>{" "}
              <span className="Agro ">&nbsp;Agro</span>&nbsp; Food Industries
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;