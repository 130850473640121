import React from "react";
import Team from "../../Components/Team/Team";
import Abouti from '../../img/about/abouti.jpg';
import Pro53 from '../../img/about/pro53.jpg';
import six from '../../img/six.jpg'
import mainLogo from '../../img/mainLogo.png'
import shakehand from '../../img/shake_hand.jpg'
const About = () => {
  return (
    <>

      <div className="heading pt-5">

        <div className="banner-img py-5">
          <div className="container bg-light py-4">
            <img className="py-2 w-100" src={shakehand} />
          </div>
        </div>

        <div className="container bg-light py-4">
          <h2 className="text-uppercase pt-4">About us</h2>
          <hr style={{ color: "green", height: "3px"}} />
          <div className="row">
            <div className="col-md-8">
              <div className="d-flex">

                <div className="pt-1">
                  <ul style={{ listStyle: "disc" }}>

                    <li className='m-1'><b>D.B. Agro Food Industries</b> is a group company of <b>DESHMUKH BROTHERS</b>, who are in diversified business of
                      <ul style={{ listStyle: "disc" }}>
                        <li><b>Petroleum Industry</b></li>
                        <li><b>Mechanical Industry</b></li>
                        <li><b>Food & Beverage Manufacturing Industry</b></li>
                        <li><b>Export Business</b></li>
                      </ul>
                    </li>
                    <li className='m-1'>We are having a team of highly qualified, dynamic & experienced professionals who are well aware of quality parameters of each & every products.</li>
                    <li className='m-1'>We adopt latest technologies to ensure the best possible quality standards of our products.</li>
                    <li className='m-1'>We have developed strong supply-chain management system to ensure Delivery within the time frame.</li>
                    <li className='m-1'>We adopt customer friendly managerial practice to create the strong business relationship with our client.</li>
                    <li className='m-1'>The company is located in <b>Sangli district of Maharashtra state (INDIA)</b>.</li>
                    <li className='m-1'><b>Sangli has the highest Grapes, Sugarcane, Banana, Pomegranate, Watermelon, Sugarcane, Onion growing belt in India</b>.</li>

                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-4 px-5 py-2">
              <img style={{height:'78%',width:'87%'}} src={mainLogo} alt="" />
            </div>
          </div>
          <hr />

          {/* <div className="col-md-8 pt-2">
            <div className='key-points'>
              <ul style={{ listSytle: "disc" }}>
                <h3>Infrastructure</h3>

                <li className='m-3'>We operate through modern <b>pack house / cold storage facility for Grapes, Pomegranate &amp; Fresh Vegetables in a BRC and APEDA</b> accredited pack house.</li>
                <li className='m-3'>We also have Onion packing facility in the center of growing area.</li>
              </ul>
              <hr />
              
              <ul style={{ listSytle: "disc" }}>
                <h3>Quality</h3>
                <li className='m-3'>Being quality conscious organization ,all our produce from associated growers are closely monitored in various growth
                  stages till <b>packaging, the produce can be tested in APEDA recognized laboratories</b> as per customer’s need.</li>
              </ul>
              <hr />
              
              <ul style={{ listSytle: "disc" }}>
                <h3>Packing</h3>
                <li className='m-3'>Packing is carried out in <b>APEDA Approved pack house in hygienic packaging material </b>to suit end users requirements for
                  export as well as domestic market.</li>
              </ul>
              <hr />
              
              <ul style={{ listSytle: "disc" }}>
                <h3>How We Works</h3>
                <li className='m-3'><b>DB Agro Food is associated with number of growers and provides them techno-managerial support since cultivation.</b></li>
                <li className='m-3'>We have our own professional team which regularly is in touch with all growers in area of crop management, certifications, food safety and quality assurance.</li>
                <li className='m-3'>Our principle is to utilize our comprehensive experience to provide highest quality and excellent service to all the customers.</li>
                <li className='m-3'>We mainly export to <b>Netherlands, Germany, France, Italy, Canada, UK, Malaysia, Thailand, Singapore & Gulf Countries.</b></li>
              </ul>
              <hr />
              
              <ul style={{ listSytle: "disc" }}>
                <h3>Mission</h3>
                <li className='m-3'><b>“Quality is not an accident, miracle or an assumed thing but it is continuous</b> and sustained efforts of strong
                  techno-managerial skill and<b> dedication towards target.</b> We are committed to ensure our strong privilege to fulfill
                  the requirements of our valuable customers and strengthen the confidence and improve the prosperity of growers.”</li>
              </ul>
            </div>
          </div> */}
        </div>
      </div >

      <Team />
      {/* <Footer /> 
      {/* <Navbar />
      <div className="about container">
        <div className="areas d-flex justify-content-center align-items-center">
          <div className="areaInfo  me-5">
            <div className="m-5">
              <div class=" mx-auto mb-5">
                <h2 class=" text-left mb-5 abouth2" id="">
                  Growing Areas
                </h2>
              </div>
              <p>
                a) Creating awareness about the new techniques/methods of
                agriculture.
              </p>
              <p>
                b) Providing guidance for maximum output with minimum costs.
              </p>
              <p>c) Making them accessible to the organized markets.</p>
              <p>
                d) Updating them about the schemes issued by the state as well
                as the central governments for agriculture
              </p>
            </div>
          </div>
          <div className="areaImg p-5 ms-5">
            <img src="https://images.pexels.com/photos/5725589/pexels-photo-5725589.jpeg?cs=srgb&dl=pexels-tima-miroshnichenko-5725589.jpg&fm=jpg" aly="err" className="img-fluid"/>
          </div>
        </div>
      </div>
      <div className="about container">
        <div className="areas d-flex">
          <div className="areaInfo2 w-50  mx-2">
            <div className="me-5">
              <div class="mx-auto mb-5">
                <h2 class="text-left mb-5 abouth2" id="">
                  FROM ORIGIN TO FUTURE
                </h2>
              </div>
              <div>
              <p>
                “DBAgro Group” is an emerging agricultural-based group,
                recognized name in the field of agriculture & allied activities
                with more than 15 years of Industry experience in agricultural
                inputs from seeds to organic Fertilizers
              </p>
              <p>
                Understanding the need for Global Revolution, providing a
                helping hand to our marginal farmers to make them walk the
                pathway of success. As a Parent company, pillar to our farming
                cooperation, we strive to create a remark across the Globe
              </p>
              <p>
                Our Farmer’s co-operative, “Shree Agro Krushi Farmers Producer
                Company Ltd” is owned and managed by diligent farmers, providing
                fair equity and value end to consumers. Our company has its deep
                roots in agricultural practices.
              </p>
              </div>
            </div>
          </div>
          <div className="areaImg p-5 ">
            <img
              src="https://images.pexels.com/photos/233698/pexels-photo-233698.jpeg?cs=srgb&dl=pexels-paul-loh-233698.jpg&fm=jpg"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div className="about container">
        <div className="areas d-flex">
          <div className="areaImg p-5 ">
            <img
              src="https://images.pexels.com/photos/3321796/pexels-photo-3321796.jpeg?cs=srgb&dl=pexels-matheus-bertelli-3321796.jpg&fm=jpg"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="areaInfo3 w-50  mx-2">
            <div className="m-5">
              <div class=" mx-auto mb-5">
                <h2 class=" text-left mb-5 abouth2" id="">
                  WHO WE ARE
                </h2>
              </div>
              <p>
                We are a team of passionate, enthusiastic farmers with an
                un-quenching desire to satisfy the demands of our consumers as
                we believe in "Vasudhaiv Kutumbakam" i.e "World is a family" and
                as a family, we understand its essentials too.
              </p>
              <p>
                Our priority has been always to strive for quality produce,
                which begins with good agricultural practices to the application
                of scientific post-harvest methods. We are a network of over
                2,000 farmers that have come a long way together with a twist of
                forming an entrepreneurial business.
              </p>
              <p>
                Our commitment to our consumers a superior quality and
                environmentally friendly range of products. We have a rich
                diversity in products, offering a range of fruits includes
                Pomegranates, Orange, Grapes, Mango, Custard Apple, Fig,
                Strawberry, etc.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Team />
      <Footer /> */}
    </>
  );
};

export default About;
