import React from "react";
import grow1 from "../../img/grow1.jpg";
import grow2 from "../../img/grow2.jpg";
import sell from "../../img/sell.jfif";

const Ourprocess = () => {
  return (
    <div className="container ourProcess my-5">
      <div class="prodHeader">
        <h2 class="prodh mb-5 text-center" id="">
          Our Process
        </h2>
      </div>
      <div className="d-flex justify-content-center align-items-center bd-highlight my-5 ourProcesscard">
        <div className="px-5 mb-5 bd-highlight">
          <div className="card" style={{ width: "18rem" }}>
            <div className="card-body">
              <div className="processNo">1</div>
              <h5 className="card-title mb-3">WE MANUFACTURE</h5>
              <ul>
                <li>We manufacture Packaged Mineral Water.</li>
                <li>We are having in-plant laboratory for quality checking to meet up the required standards.</li>
                <li>We are doing job-work for big brands.</li>
              </ul>
              {/* <p>• We manufacture Packaged Mineral Water.</p>
              <p>• We are having in-plant laboratory for quality checking to meet up the required standards.</p>
              <p>• We are doing job-work for big brands. </p> */}
            </div>
            <div className="cardImg">
              {" "}
              <img
                src="https://images.pexels.com/photos/2382904/pexels-photo-2382904.jpeg?cs=srgb&dl=pexels-kelly-l-2382904.jpg&fm=jpg"
                className="card-img-top p-2"
                width="300"
                height="220"
                alt="..."
                style={{"border-radius":"25px"}}
              />
            </div>
          </div>
        </div>

        <div className="px-5 mb-5 bd-highlight">
          <div className="card " style={{ width: "18rem" }}>
            <div className="card-body">
              <div className="processNo">2</div>
              <h5 className="card-title mb-3">WE TRADE</h5>
              <ul>
                <li>We Supply Fresh Fruits &amp; Vegetables.</li>
                <li>We also supply processed foods &amp; beverages.</li>
                <li>We deal in the plastic material such as PET Preforms, Caps/enclosures, PET Bottles.</li>
              </ul>
              {/* <p>•    We Supply Fresh Fruits &amp; Vegetables.</p>
              <p>•    We also supply processed foods &amp; beverages.</p>
              <p>•    We deal in the plastic material such as PET Preforms, Caps/enclosures, PET Bottles.</p> */}

            </div>
            <div className="cardImg">
              {" "}
              <img
                src="https://images.pexels.com/photos/2889093/pexels-photo-2889093.jpeg?cs=srgb&dl=pexels-mark-stebnicki-2889093.jpg&fm=jpg"
                className="card-img-top p-2"
                width="300"
                height="220"
                alt="..."
                style={{"border-radius":"25px"}}
              />
            </div>
          </div>
        </div>

        <div className="px-5 mb-5 bd-highlight">
          <div className="card " style={{ width: "18rem" }}>
            <div className="card-body">
              <div className="processNo">3</div>
              <h5 className="card-title mb-3">WE DELIVER</h5>
              <ul>
                <li>We have Efficient Supply Chain.</li>
                <li>We sell all over the world.</li>
                <li>Developing new markets.</li>
                <li>Consistent &amp; Quality Supply.</li>
                <br></br>
                <br></br>
              </ul>

              {/* <p>• We have Efficient Supply Chain.</p>
              <p>• We sell all over the world.</p>
              <p>• Developing new markets.</p>
              <p>• Consistent &amp; Quality Supply. </p> */}
            </div>
            <div className="cardImg">
              {" "}
              <img
                src="https://images.pexels.com/photos/2680058/pexels-photo-2680058.jpeg?cs=srgb&dl=pexels-johnmark-barit-2680058.jpg&fm=jpg"
                className="card-img-top p-2"
                width="300"
                height="220"
                alt="..."
                style={{"border-radius":"25px"}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ourprocess;
