import React, { useRef, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import banana from "../../img/banProduct.jpg";
import bananaa from "../../img/banana.jpg";
import mango from "../../img/mango.svg";
import water from "../../img/water/banner.jpg"
import water1 from "../../img/water/water1.jpg"
import water500ml from "../../img/water500ml.jpeg"
// import water4 from "../../img/water4.jpg"
import water1L from "../../img/water1L.jpeg"
import water2L from "../../img/water2L.jpeg"
import water5L from "../../img/water5L.jpeg"
// import water4 from "../../img/water4.jpg"
import CarouselContainer from "./CarouselContainer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";import emailjs from "emailjs-com";
import { Modal } from 'react-bootstrap'

const Water = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_pjg4e6o",
        "template_iuu461l",
        form.current,
        "user_RzK5JvmhlZhCIIILWmq4h"
      )
      .then(
        (result) => {
          toast.success("message sent successfully");
          console.log(result.text);
          window.location.reload();
        },
        (error) => {
          toast.danger("fill all fields");
          console.log(error.text);
        }
      );
  };

  return (
    <>

      <div className="grapes-section py-5">
        <div className="banana-img py-5">
          <div className="container  bg-light py-3 px-3">
            <img src={water}
              alt="err"
              className="img-fluid" />
          </div>
        </div>

        <div className="container bg-light py-4">
          <h2 className="text-uppercase pt-1" style={{ color: "green" }}>Mineral Water</h2>
          <hr style={{ color: "red", height: "2px" }} />

          <div className="row">
            <div className="col-md-6">
              <ul>
              <li className="mt-3"> Dawar's packaged drinking water was introduced in India in the year 2008. The brand promises the assurance of clean and safe drinking water. Every drop of Dawar's goes through a rigorous 10 step Purification process
                </li>
                <li className="mt-3">Dawar's has been a household name for decades.  Our bond with our consumers and their support have sustained our leadership in the category.  Dawar's has always believed in its promise of providing safe, pure & healthy mineral water to the consumer.
                </li>
                <li className="mt-3">
                We have a strong presence within india and a strong distribution network of 500 Distributors & strong supply chain.
                </li>
                <li className="mt-3">
                We are the most trusted mineral water brand in India.
                </li>
               
                <li className="mt-3"> Each bottle is filled with trust of purity & surety
                </li>
                <li className="mt-3"><b>Variety:</b> Water (1L) Water (2L) Water (5L)
                </li>

               
              </ul>
            </div>

            <div className="col-md-6 pt-5 px-5">
              <div className="procard1 px-5">
                <img src={water1}
                  alt="err"
                  className="img-fluid border w-100" />
              </div>
            </div>
          </div>

        

          <button onClick={handleShow} className="btn btn-primary" 
            style={{
              background: "#f97233",
              border: "0",
              padding: "13px 50px",
              color: "#ffffff",
              transition: "0.4s",
              borderRadius: "0",
              marginLeft: "20px"}}>
              Enquiry Here
          </button>

          <hr />

          <Modal show={show} onHide={handleClose} size="lg" data-dismiss="modal" data-toggle="modal" style={{ marginRight: '1.5em' }}>
            <Modal.Header closeButton>
              <Modal.Title>Enquiry Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-lg-8">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  action="forms/contact.php"
                  method="post"
                  className="php-email-form"
                >
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 ">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        placeholder="Subject"
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <textarea
                        className="form-control"
                        name="message"
                        rows={6}
                        placeholder="Message"
                        required
                        defaultValue={""}
                      />
                    </div>
                    <div className="col-md-12 text-center">
                      {/* <div className="loading">
                        Loading
                      </div>
                      <div className="error-message" />
                      <div className="sent-message">
                        Your message has been sent.
                        Thank you!
                      </div> */}
                      <button type="submit" className="btn btn-primary">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Modal.Body>

          </Modal>
          <div className="container bg-light py-4">
            <div className="row">
              <div className="col-md-3 pb-4">
                <div className="procard1 border">
                  <img src={water500ml}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={water1L}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={water2L}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={water5L}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <Navbar />
        <div className="banana">
          <div>
            <img src={water} alt="err" className="img-fluid mt-5 pt-4" />
          </div>
          <div class="prodHeader my-5">
            <h2 class="prodh  text-center " id="">
              Water
            </h2>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5">
            <div className="procard1">
              <img src={water1} alt="err" className="img-fluid" />
            </div>
            <div className="procard2 p-5">
              <h2 class="text-left mb-4" id="">
                Water
              </h2>
              <p className="text-justify">
                India is the land of Mangos & It is a National fruit of the
                country. Mangos are grown in varied places in India giving
                diversity in taste. Mango is the "king of fruits" & it has many
                famous varieties like ALPHONSO, KESAR, NEELAM, BANAGANAPLLI,
                etc.
              </p>
            </div>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5 ">
            <div className="proCardInfo p-5">
            <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Variety : </strong> Alphonso, Kesar
                  </p>
                </div>
              </div>
              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Brix : </strong> 13 to 24
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Packaging : </strong>&nbsp;Corrugated Carton
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Average Weight : </strong> 180 to 300 Gm
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Shelf Life : </strong> Up to 10/12 days
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">No of Pieces : </strong> 12 Pcs, 6 Pcs
                  </p>
                </div>
              </div>
             
              
            </div>
            <div className="proCardInfo2">
                <img src="https://images.pexels.com/photos/2872767/pexels-photo-2872767.jpeg?cs=srgb&dl=pexels-aleksandar-pasaric-2872767.jpg&fm=jpg" alt="" className="img-fluid"/>
            </div>
          </div>
        </div>
        <CarouselContainer />
      </div>
      <Footer /> */}
    </>
  );
};

export default Water;
