import React from "react";
import banana from "../../img/banProduct.jpg";
import bananaa from "../../img/banana.jpg";
import mango from "../../img/mango.svg";
import MaidaBanner from "../../img/maida/NewMaidaBanner.jpg";
import Maida1 from "../../img/maida/maida.jpg";
import M1 from "../../img/maida/m1.jpeg";
import M2 from "../../img/maida/m2.jpeg";
import M3 from "../../img/maida/m3.jpg";
import M4 from "../../img/maida/m4.jpg";
import CarouselContainer from "./CarouselContainer";
const Maida = () => {
  return (
    <>
      <div className="grapes-section py-5">
        <div className="banana-img py-5">
          <div className="container  bg-light py-3 px-3">
            <img src={MaidaBanner}
              alt="err"
              className="img-fluid"
              style={{width:"100%", height:"35rem"}}
              />
          </div>
        </div>

        <div className="container bg-light py-4">
          <h2 className="text-uppercase pt-1" style={{ color: "green" }}>Maida</h2>
          <hr style={{ color: "green", height: "2px" }} />

          <div className="row">
            <div className="col-md-6">
              <ul>
                <li className="mt-3"> Maida, also known as Fine wheat Flour is another finely milled form of wheat.
                </li>
                <li className="mt-3"> Depending upon the quality of wheat and its usage, there are a variety of fine wheat flours available.
                </li>
                <li className="mt-3">It is often used for preparing delectable dishes such as Cookies, Breads, Pastries, and Cakes, Some of the Indian snacks like Samosas, Kachoris and many more.
                </li>
                <li className="mt-3"><b style={{ color: "green" }}>HS CODE :</b> 1101 00 00</li>
                <li className="mt-3"><b>Color:</b> White
                </li>
                <li className="mt-3"><b>Protein:</b> 11-13% Min
                </li>
                <li className="mt-3"><b>Moisture:</b> 14% Max
                </li>
                <li className="mt-3"><b>Ash:</b> 0.65% Max
                </li>
                <li className="mt-3"><b>Wet Gluten:</b> 27% Min
                </li>
                <li className="mt-3"><b>Dry Gluten:</b> 8.5% Min
                </li>
                <li className="mt-3"><b>Packing:</b> Multi wall plain new PP bags of 25 kgs or 50 kgs.
                </li>
                <li className="mt-3"><b>Container Loading:</b> 23 MT per 20 FT.
                </li>
              </ul>
            </div>

            <div className="col-md-6 pt-5 px-5">
              <div className="procard1 px-5">
                <img src={Maida1}
                  style={{height:"32rem"}}
                  alt="err"
                  className="img-fluid border w-100" />
              </div>
            </div>
          </div>

          <hr />

          <div className="container bg-light py-4">
            <div className="row">
              <div className="col-md-3 pb-4">
                <div className="procard1 border">
                  <img src={M1}
                    style={{height:"16rem"}}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={M2}
                    style={{height:"16rem"}}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={M3}
                    style={{height:"16rem"}}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4">
                <div className="procard1 border">
                  <img src={M4}
                    style={{height:"16rem"}}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <Navbar />
        <div className="banana">
          <div>
            <img src={banana} alt="err" className="img-fluid" />
          </div>
          <div class="prodHeader my-5">
            <h2 class="prodh  text-center " id="">
              Banana
            </h2>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5">
            <div className="procard1">
              <img src={bananaa} alt="err" className="img-fluid" />
            </div>
            <div className="procard2 p-5">
              <h2 class="text-left mb-4" id="">
                Banana
              </h2>
              <p className="text-justify">
                India is the land of Mangos & It is a National fruit of the
                country. Mangos are grown in varied places in India giving
                diversity in taste. Mango is the "king of fruits" & it has many
                famous varieties like ALPHONSO, KESAR, NEELAM, BANAGANAPLLI,
                etc.
              </p>
            </div>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5 ">
            <div className="proCardInfo p-5">
            <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Variety : </strong> Alphonso, Kesar
                  </p>
                </div>
              </div>
              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Brix : </strong> 13 to 24
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Packaging : </strong>&nbsp;Corrugated Carton
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Average Weight : </strong> 180 to 300 Gm
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Shelf Life : </strong> Up to 10/12 days
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">No of Pieces : </strong> 12 Pcs, 6 Pcs
                  </p>
                </div>
              </div>
             
              
            </div>
            <div className="proCardInfo2">
                <img src="https://images.pexels.com/photos/2872767/pexels-photo-2872767.jpeg?cs=srgb&dl=pexels-aleksandar-pasaric-2872767.jpg&fm=jpg" alt="" className="img-fluid"/>
            </div>
          </div>
        </div>
        <CarouselContainer />
      </div>
      <Footer /> */}
    </>
  );
};

export default Maida;
