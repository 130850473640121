import React from "react";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import sugar from "../../img/sugar-min.jpg";
import bananaa from "../../img/banana.jpg";
import sugar2 from "../../img/sugar.jpg";

import sugar1 from "../../img/sugar/sugar1.jfif";
import sugar4 from "../../img/sugar/sugar4.jpeg";
import sugar5 from "../../img/sugar/sugar5.jpeg";

import mango from "../../img/mango.svg";
import sugar3 from "../../img/sugar/sugar3.jpg";
import CarouselContainer from "./CarouselContainer";
const Sugar = () => {
  return (
    <>
      <div className="grapes-section py-5">
        <div className="banana-img py-5">
          <div className="container  bg-light py-3 px-3">
            <img src={sugar}
              alt="err"
              className="img-fluid" />
          </div>
        </div>

        <div className="container bg-light py-4">
          <h2 className="text-uppercase pt-1" style={{ color: "green" }}>sugar</h2>
          <hr style={{ color: "green", height: "2px" }} />

          <div className="row">
            <div className="col-md-6">
              <ul>
                <li className="mt-3">India is the second largest producer of sugar in the
                  world after Brazil. According to data from the Indian Sugar Mills Association,
                  the country's sugar mill produce 268.21 lakh (26,821,000) tonnes of sugar
                  between October 1, 2019 and May 31, 2020.
                </li>
                <li className="mt-3"><b style={{ color: "green" }}>HS CODE :</b> 1701 91 00</li>
                <li className="mt-3"><b>Product:</b> Sugar
                </li>
                <li className="mt-3"><b>Quantity:</b> Min One Container
                </li>
                <li className="mt-3"><b>Packaging:</b> 25/50 Kg in PP Bag(Customized)
                </li>
                <li className="mt-3"><b>Availability:</b>Throughout the year.
                </li>
              </ul>
            </div>

            <div className="col-md-6 pt-5 px-5">
              <div className="procard1 px-5">
                <img src={sugar3}
                  alt="err"
                  className="img-fluid border w-100" />
              </div>
            </div>
          </div>

          <hr />

          <div className="container bg-light py-4">
            <div className="row">
              <div className="col-md-4 pb-4">
                <div className="procard1 border">
                  <img src={sugar1}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-4 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={sugar4}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-4 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={sugar5}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <Navbar />
        <div className="banana">
          <div>
            <img src={sugar} alt="err" className="img-fluid mt-5 pt-2" />
          </div>
          <div class="prodHeader my-5">
            <h2 class="prodh  text-center " id="">
              Sugar
            </h2>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5">
            <div className="procard1">
              <img src={sugar2} alt="err" className="img-fluid" />
            </div>
            <div className="procard2 p-5">
              <h2 class="text-left mb-4" id="">
                Sugar
              </h2>
              <p className="text-justify">
                India is the land of Mangos & It is a National fruit of the
                country. Mangos are grown in varied places in India giving
                diversity in taste. Mango is the "king of fruits" & it has many
                famous varieties like ALPHONSO, KESAR, NEELAM, BANAGANAPLLI,
                etc.
              </p>
            </div>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5 ">
            <div className="proCardInfo p-5">
            <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Variety : </strong> Alphonso, Kesar
                  </p>
                </div>
              </div>
              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Brix : </strong> 13 to 24
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Packaging : </strong>&nbsp;Corrugated Carton
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Average Weight : </strong> 180 to 300 Gm
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Shelf Life : </strong> Up to 10/12 days
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">No of Pieces : </strong> 12 Pcs, 6 Pcs
                  </p>
                </div>
              </div>
             
              
            </div>
            <div className="proCardInfo2">
                <img src={sugar3} alt="" className="img-fluid"/>
            </div>
          </div>
        </div>
        <CarouselContainer />
      </div>
      <Footer /> */}
    </>
  );
};

export default Sugar;
