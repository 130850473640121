import React from 'react';
import logo from "../../img/mainLogo.png";
const Footer = () => {
  return (
    <div>
      <footer id="footer" className="border-top">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>Address:</h3>

                <p>
                  kirloskarwadi Rd, <br />
                  Burli, Maharashtra 416308<br />
                  India <br /><br />
                  <strong>Phone:</strong> <span>+91 77768 81009</span><br />
                  <strong>Email:</strong> contact@dbagrofood.com<br />
                </p>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><i className="bx bx-chevron-right" /> <a href="/">Home</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="/about">About us</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Services</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Terms of service</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Privacy policy</a></li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Manufacturing</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Distribution</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Trading</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Supplier</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">On Field Service</a></li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 footer-newsletter m-auto">
                <img src={logo} className="ms-5" alt="err" width="250" height="200" />
              </div>
            </div>
          </div>
        </div>
        <div className="container d-md-flex py-4">
          <div className="me-md-auto text-center text-md-start">

            <div className="credits ">

              Designed and Developed by <a href="https://5techg.com/#/">5TechG Lab</a>
            </div>
          </div>
          <div className="social-links text-center text-md-right pt-3 pt-md-0">
            <a href="#" className="twitter"><i className="bx bxl-twitter" /></a>
            <a href="#" className="facebook"><i className="bx bxl-facebook" /></a>
            <a href="#" className="instagram"><i className="bx bxl-instagram" /></a>
            <a href="#" className="google-plus"><i className="bx bxl-skype" /></a>
            <a href="#" className="linkedin"><i className="bx bxl-linkedin" /></a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
