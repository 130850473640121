import React from "react";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import banana from "../../img/banana-min.jpg";
import bananaa from "../../img/banana.jpg";
import ban8 from "../../img/ban8.jpeg";
import ban3 from "../../img/ban3.jpeg";
import ban4 from "../../img/ban4.jpeg";
import ban6 from "../../img/ban6.png";
import ban7 from "../../img/ban7.jpeg";
import mango from "../../img/mango.svg";
// import CarouselContainer from "./CarouselContainer";
const Banana = () => {
  return (
    <>

      {/* <Navbar /> */}

      <div className="banana-section py-5">
        <div className="banana-img py-5">
          <div className="container  bg-light py-3 px-3">
            <img src={banana} alt="err" className="img-fluid" />
          </div>
        </div>

        <div className="container bg-light py-4">
          <h2 className="text-uppercase pt-1" style={{ color: "green" }}>BANANA</h2>
          <hr style={{ color: "green", height: "2px" }} />

          <div className="row">
            <div className="col-md-6">
              <ul>
                <li className="mt-3">Bananas are one of the world's most appealing fruits. It’s one
                  of the greatest healthy eating snacks and a superfood. A wide
                  variety of health benefits are associated with curvy yellow
                  fruit.
                </li>
                <li className="mt-3">Bananas are high in potassium and pectin, a form of
                  fiber. They can also be a good way to get magnesium and vitamins
                  C and B6.
                </li>
                <li className="mt-3"><b style={{ color: "green" }}>HS CODE :</b> 0803 90 10</li>
                <li className="mt-3"><b>Variety:</b> G9/Cavendish
                </li>
                <li className="mt-3"><b>Brix:</b> 13 to 24
                </li>
                <li className="mt-3"><b>Total Boxes:</b> 1540
                </li>
                <li className="mt-3"><b>Storage Temperature:</b> +13˚C
                </li>
                <li className="mt-3"><b>Availability:</b>Round the year</li>
              </ul>
            </div>

            <div className="col-md-6">
              <div className="procard1 px-5">
                <img src={bananaa} alt="err" className="img-fluid w-75" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container bg-light py-4">
        <div className="row">
          <div className="col-md-3 pb-4">
            <div className="procard1 border">
              <img src={ban8}
                alt="err"
                style={{ height: '450px' }}
                className="img-fluid w-100" />
            </div>
          </div>
          <div className="col-md-3 pb-4 pb-4">
            <div className="procard1 border">
              <img src={ban6}
                style={{ height: '450px' }}
                alt="err"
                className="img-fluid w-100" />
            </div>
          </div>
          <div className="col-md-3 pb-4 pb-4">
            <div className="procard1 border">
              <img src={ban7}
                style={{ height: '450px' }}
                alt="err"
                className="img-fluid w-100" />
            </div>
          </div>
          <div className="col-md-3 pb-4 pb-4">
            <div className="procard1 border">
              <img src={ban4}
                style={{ height: '450px' }}
                alt="err"
                className="img-fluid w-100" />
            </div>
          </div>

        </div>
      </div>
      {/* <div>

        <div className="banana">
          <div>
            <img src={banana} alt="err" className="img-fluid" />
          </div>
          <div class="prodHeader my-5">
            <h2 class="prodh  text-center " id="">
              Banana
            </h2>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5 sec1">
            <div className="procard1">
              <img src={bananaa} alt="err" className="img-fluid" />
            </div>
            <div className="procard2 p-5">
              <h2 class="text-left mb-4" id="">
                Banana
              </h2>
              <p className="text-justify">
                Bananas are one of the world's most appealing fruits. It’s one
                of the greatest healthy eating snacks and a superfood. A wide
                variety of health benefits are associated with curvy yellow
                fruit.
                <br /> Bananas are high in potassium and pectin, a form of
                fiber. They can also be a good way to get magnesium and vitamins
                C and B6.
              </p>
            </div>
          </div>

          
          <div className="container d-flex flex-row justify-content-around align-items-center my-5 sec2">
            <div className="proCardInfo p-5">
              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Variety : </strong> G9 /
                    Cavendish
                  </p>
                </div>
              </div>
              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Brix : </strong> 13 to 24
                  </p>
                </div>
              </div>





              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Total Boxes: : </strong>&nbsp; 1540
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Storage Temperature : </strong> +13˚C


                  </p>
                </div>
              </div>
            </div>
            <div className="proCardInfo2">
              <img
                src="https://images.pexels.com/photos/2872767/pexels-photo-2872767.jpeg?cs=srgb&dl=pexels-aleksandar-pasaric-2872767.jpg&fm=jpg"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <CarouselContainer />
      </div> */}
      {/* <Footer /> */}
    </>
  );
};

export default Banana;
