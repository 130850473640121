import React from "react";
import MangoCategory from "./MangoCategory/MangoCategory";

import Alphanso from "../../img/mango/Alphanso.jpg";
import M1 from "../../img/mango/m1.jpg";
import M2 from "../../img/mango/m2.jpg";
import M3 from "../../img/mango/m3.jpg";
import M4 from "../../img/mango/m4.jpg";
import CarouselContainer from "./CarouselContainer";
const AlphansoMango = () => {
  return (
    <>
      <div className="mango-section py-5">
        <div className="mango-img py-5">
          <div className="container  bg-light py-3 px-3">
            <img src="https://static.wixstatic.com/media/14e6e1_6a07385d48ca4f97a36f5f2226c5cfd5~mv2.jpg/v1/fill/w_1899,h_619,al_c,q_85,usm_0.66_1.00_0.01/14e6e1_6a07385d48ca4f97a36f5f2226c5cfd5~mv2.webp" alt="err" className="img-fluid" />
          </div>
        </div>


        <div className="container bg-light py-4">
          <h2 className="text-uppercase pt-1" style={{ color: "green" }}>FRESH MANGO</h2>
          <hr style={{ color: "green", height: "2px" }} />

          <div className="procard2 px-2 pb-4">
            <li className="text-justify">
              India is the land of Mangos & It is a National fruit of the
              country. Mangos are grown in varied places in India giving
              diversity in taste. Mango is the "king of fruits" & it has many
              famous varieties like ALPHONSO, KESAR, NEELAM, BANAGANAPLLI,
              etc.
            </li>
          </div>

          <div>
            <MangoCategory />
          </div>

          <div>
            <h2 className="text-uppercase pt-1" style={{ color: "green" }}>ALPHANSO MANGO</h2>
            <hr style={{ color: "green", height: "2px" }} />

            <div className="row">
              <div className="col-md-6">
                <div className="procard2 px-2 pb-4">
                  <b6 className="text-justify">
                    Exporter and supplier of Alphanso Mango. Our range of Alphanso Mango is available with following features and specifications:
                  </b6>

                  <ul>
                  <li className="mt-3"><b style={{ color: "green" }}>HS CODE :</b> 0804 50 21</li>
                    <li className="mt-3"><b>Variety:</b> Alphanso
                    </li>
                    <li className="mt-3"><b>Packaging:</b> Net weight of box 4.00 kg
                    </li>

                    <b3 className="py-3"><b>Details:</b></b3>
                    <li className="mt-3"><b>Size:</b> Medium 250 gm & above.
                    </li>
                    <li className="mt-3"><b>Shape:</b> Ovate
                    </li>
                    <li className="mt-3"><b>Skin-Medium Thick:</b> Yellow
                    </li>
                    <li className="mt-3"><b>Taste:</b> Sweet, Fibreless
                    </li>
                    <li className="mt-3"><b>Availability:</b> March/April/May.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 pt-1 px-5">
                <div className="procard1 px-5">
                  <img src={Alphanso}
                    alt="err"
                    className="img-fluid border w-75" />
                </div>
              </div>
            </div>

            <hr />

            <div className="container bg-light py-4">
              <div className="row">
                <div className="col-md-3 pb-4">
                  <div className="procard1 border">
                    <img src={M1}
                      alt="err"
                      className="img-fluid w-100" />
                  </div>
                </div>
                <div className="col-md-3 pb-4 pb-4">
                  <div className="procard1 border">
                    <img src={M2}
                      alt="err"
                      className="img-fluid w-100" />
                  </div>
                </div>
                <div className="col-md-3 pb-4 pb-4">
                  <div className="procard1 border">
                    <img src={M3}
                      alt="err"
                      className="img-fluid w-100" />
                  </div>
                </div>
                <div className="col-md-3 pb-4 pb-4">
                  <div className="procard1 border">
                    <img src={M4}
                      alt="err"
                      className="img-fluid w-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <Navbar />
        <div className="banana">
          <div>
            <img src="https://static.wixstatic.com/media/14e6e1_6a07385d48ca4f97a36f5f2226c5cfd5~mv2.jpg/v1/fill/w_1899,h_619,al_c,q_85,usm_0.66_1.00_0.01/14e6e1_6a07385d48ca4f97a36f5f2226c5cfd5~mv2.webp" alt="err" className="img-fluid" />
          </div>
          <div class="prodHeader my-5">
            <h2 class="prodh  text-center " id="">
              Mango
            </h2>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5 sec1">
            <div className="procard1">
              <img src="https://static.wixstatic.com/media/22bb1d_1819bb0bbea64268948f8baffff994d4~mv2.jpg/v1/crop/x_482,y_0,w_3773,h_3456/fill/w_626,h_573,al_c,q_80,usm_0.66_1.00_0.01/Product_Mango.webp" alt="err" className="img-fluid" />
            </div>
            <div className="procard2 p-5">
              <h2 class="text-left mb-4" id="">
                Mango
              </h2>
              <p className="text-justify">
                India is the land of Mangos & It is a National fruit of the
                country. Mangos are grown in varied places in India giving
                diversity in taste. Mango is the "king of fruits" & it has many
                famous varieties like ALPHONSO, KESAR, NEELAM, BANAGANAPLLI,
                etc.
              </p>
            </div>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5 sec2">
            <div className="proCardInfo p-5">
            <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Variety : </strong> Alphonso, Kesar
                  </p>
                </div>
              </div>
              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Brix : </strong> 13 to 24
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Packaging : </strong>&nbsp;Corrugated Carton
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Average Weight : </strong> 180 to 300 Gm
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Shelf Life : </strong> Up to 10/12 days
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">No of Pieces : </strong> 12 Pcs, 6 Pcs
                  </p>
                </div>
              </div>
             
              
            </div>
            <div className="proCardInfo2">
                <img src="https://static.wixstatic.com/media/14e6e1_5b1de125e7b74bbf99be3dbfa8275846~mv2.jpg/v1/crop/x_0,y_19,w_2680,h_2640/fill/w_437,h_430,al_c,q_80,usm_0.66_1.00_0.01/Mango_Content%20Image.webp" alt="" className="img-fluid"/>
            </div>
          </div>
        </div>
        <CarouselContainer />
      </div> */}
      {/* <Footer /> */}
    </>
  );
};

export default AlphansoMango;
