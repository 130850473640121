import React from "react";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";

import mango from "../../img/grapes.svg";

import Pro1 from "../../img/grapes/pro1.jpg";
import G1 from "../../img/grapes/g1.jpg";
import G2 from "../../img/grapes/g2.jpg";
import G3 from "../../img/grapes/g3.jpg";
import G4 from "../../img/grapes/g4.jpg";
import CarouselContainer from "./CarouselContainer";
const Grapes = () => {
  return (
    <>
      {/* <Navbar /> */}

      <div className="grapes-section py-5">
        <div className="banana-img py-5">
          <div className="container  bg-light py-3 px-3">
            <img src="https://static.wixstatic.com/media/14e6e1_02bb039c57f349268dcab5356c8183ef~mv2.jpg/v1/fill/w_1899,h_619,al_c,q_85,usm_0.66_1.00_0.01/14e6e1_02bb039c57f349268dcab5356c8183ef~mv2.webp"
              alt="err"
              className="img-fluid" />
          </div>
        </div>

        <div className="container bg-light py-4">
          <h2 className="text-uppercase pt-1" style={{ color: "green" }}>GRAPES</h2>
          <hr style={{ color: "green", height: "2px" }} />

          <div className="row">
            <div className="col-md-6">
              <ul>
                <li className="mt-3">Grapes are widely considered the "Queen of fruits" for their
                  nutritional value. It can be of varying colour crimson, black,
                  dark blue, yellow, green, orange, and purple.
                </li>
                <li className="mt-3"> Grapes arrive in two varieties with seeds as well as seedless
                  form.
                </li>
                <li className="mt-3"><b style={{ color: "green" }}>HS CODE :</b> 0806 10 00</li>
                <li className="mt-3"><b>Variety:</b> White Grapes: Thomson Seedless / Sonaka
                </li>
                <li className="mt-3"><b>Packaging Net wt.:</b> 4.50 kg per box (1-40 feet container 3400 boxes)./ 5 kg per box (1-40 feet container 2472 boxes)
                </li>
                <li className="mt-3"><b>Net wt.:</b> 5.00 kg Punnet (1-40 feet container 2472 boxes).
                </li>
                <li className="mt-3"><b>Net wt.:</b> 8.2 kg Punnet (1-40 feet container 2040 boxes).
                </li>
                <li className="mt-3"><b>Details:</b> Berry size: 16mm and above.
                </li>
                <li className="mt-3"><b>Brix:</b>16%.
                </li>
                <li className="mt-3"><b>Acidity:</b> 0.67 to 0.80% TSS: 18 to 20.
                </li>
                <li className="mt-3"><b>Availability:</b>Jan/Feb/March/April.
                </li>
              </ul>
            </div>

            <div className="col-md-6 pt-5 px-5">
              <div className="procard1 px-5">
                <img src={Pro1}
                  style={{height:"30rem"}}
                  alt="err"
                  className="img-fluid border w-100" />
              </div>
            </div>
          </div>

          <hr />

          <div className="container bg-light py-4">
            <div className="row">
              <div className="col-md-3 pb-4">
                <div className="procard1 border">
                  <img src={G1}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={G2}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={G3}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-md-3 pb-4 pb-4">
                <div className="procard1 border">
                  <img src={G4}
                    alt="err"
                    className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <Navbar />
        <div className="banana">
          <div>
            <img
              src="https://static.wixstatic.com/media/14e6e1_02bb039c57f349268dcab5356c8183ef~mv2.jpg/v1/fill/w_1899,h_619,al_c,q_85,usm_0.66_1.00_0.01/14e6e1_02bb039c57f349268dcab5356c8183ef~mv2.webp"
              alt="err"
              className="img-fluid"
            />
          </div>
          <div class="prodHeader my-5">
            <h2 class="prodh  text-center " id="">
              Grapes
            </h2>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5 sec1" >
            <div className="procard1">
              <img
                src="https://static.wixstatic.com/media/14e6e1_712db7207a8d4d62844067a628e3ee83~mv2.jpg/v1/crop/x_169,y_0,w_6461,h_4912/fill/w_549,h_417,al_c,q_80,usm_0.66_1.00_0.01/Grapes%20Page_Main%20Image.webp"
                alt="err"
                className="img-fluid"
              />
            </div>
            <div className="procard2 p-5">
              <h2 class="text-left mb-4" id="">
                Grapes
              </h2>
              <p className="text-justify">
                Grapes are widely considered the "Queen of fruits" for their
                nutritional value. It can be of varying colour crimson, black,
                dark blue, yellow, green, orange, and purple.
                <br />
                
              </p>
            </div>
          </div>
          <div className="container d-flex flex-row justify-content-around align-items-center my-5 sec2">
            <div className="proCardInfo ">
              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Variety : </strong> Thompson
                    Seedless, Black Seedless, Sonaka, Flame
                  </p>
                </div>
              </div>
              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Brix : </strong>16+
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Packaging : </strong>
                    &nbsp;Type 1) Net Wt 4.5 Kg Carton/ Pouch Packing Total
                    Cartons: 3400 <br /> Type 2) Net Wt 5 Kg Carton/ Punnet Packing
                    Total Cartons: 2472 <br />Type 3) Net Wt 8.2 Kg Carton/ Pouch
                    Packing Total Cartons: 2040
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Average Weight : </strong> 180
                    to 300 Gm
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Shelf Life : </strong> Up to
                    85 days
                  </p>
                </div>
              </div>

              <div className="container d-flex flex-row justify-content-start align-items-center text-center">
                <div className="svgBox m-2">
                  <img src={mango} alt="" />
                </div>
                <div className="text-center mt-2 ms-3">
                  <p className="text-dark">
                    <strong className="text-dark">Storage Temp : </strong> 0 to +2 °C
                  </p>
                </div>
              </div>
            </div>
            <div className="proCardInfo2">
              <img
                src="https://static.wixstatic.com/media/14e6e1_44f2e546f4224c98ab099540bb39ce1d~mv2.jpg/v1/fill/w_491,h_464,al_c,q_80,usm_0.66_1.00_0.01/Grapes%20Page%202nd%20Image.webp"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <CarouselContainer />
      </div> */}

    </>
  );
};

export default Grapes;
