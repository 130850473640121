import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/mainLogo.png";
import banana from '../../img/banana.jpg';
import coconut from '../../img/coconutt.jpg';
import maida from '../../img/maida/maida.jpg';
import grapes from '../../img/greps.jpg';
import gull from '../../img/gulllll.jpg';
import mango from '../../img/mango.jpg';
import pineapple from '../../img/pinapple.jpg';
import onion from '../../img/onion.jpg';
import sugar from '../../img/sugar.jpg';
import turmeric from '../../img/turmeric powder.jpg';
import water from '../../img/water.jpg';
import Mango from '../../img/mango.jpg';
import { Modal } from 'react-bootstrap'
import 'boxicons';
const Navbar = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navButton = useRef(null);
  const linksContainerRef = useRef(null);

  function collapseNav() {
    navButton.current.classList.add("collapsed");
    linksContainerRef.current.classList.remove("show");
  }
  return (
    <>
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top mb-5 navigation" >
          <div className="container">
          <button
              ref={navButton}
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            
            <a onClick={collapseNav}
            class="navbar-brand" href="#">
              <img src={logo} alt="" width="60" height="50" />
            </a>
            
            <div
              ref={linksContainerRef}
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    onClick={collapseNav}
                    className="nav-link active mx-4"
                    aria-current="page"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item mx-4">
                  <Link 
                  onClick={collapseNav}
                  className="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li 
                onClick={collapseNav}
                className="nav-item mx-4" id="productItem">

                  <div variant="primary" onClick={handleShow} className="nav-link">
                    Products
                  </div>

                </li>

                <li className="nav-item mx-4">
                  <Link onClick={collapseNav}
                  className="nav-link" to="/gallery">
                    Gallery
                  </Link>
                </li>
                <li className="nav-item mx-4">
                  <Link onClick={collapseNav}
                  className="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="d-flex justify-content-center align-items-center icons">
              <a className="pe-4 boxIcon"><i class='bx bxl-instagram bx-sm bx-tada-hover'></i></a>
              <a className="pe-4 boxIcon"><i class='bx bxl-whatsapp bx-sm bx-tada-hover'></i></a>
              <a className="pe-4 boxIcon"><i class='bx bxl-facebook-circle bx-sm bx-tada-hover'></i></a>
              <a className="boxIcon"><i class='bx bxl-twitter bx-sm bx-tada-hover'></i></a>
            </div>
          </div>

        </nav>
        <Modal show={show} onHide={handleClose} size="xl" data-dismiss="modal" data-toggle="modal" style={{ marginRight: '1.5em' }}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body><div className="d-flex flex-column gap-4">
            <h5>Products</h5>
            <div className="d-flex flex-row justify-content-start align-items-center bd-highlight mb-1">
              <Link to='/banana'><div className="p-2 bd-highlight  flexMain"><img src={banana} className="img-fluid flex1 " alt="err" /></div></Link>
              <Link to='/banana' onClick={handleClose}> <div className="p-2 mx-2 bd-highlight flex2" >Banana</div></Link>
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center bd-highlight mb-1">
              <Link to='/grapes'><div className="p-2 bd-highlight flexMain"><img src={grapes} className="img-fluid flex1 " alt="err" /></div></Link>
              <Link to='/grapes' onClick={handleClose}><div className="p-2 mx-2 bd-highlight flex2">Grapes</div></Link>
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center bd-highlight mb-1">
              <Link to='/coconut'><div className="p-2 bd-highlight flexMain"><img src={coconut} className="img-fluid flex1 " alt="err" /></div></Link>
              <Link to='/coconut' onClick={handleClose}><div className="p-2 mx-2 bd-highlight flex2">Coconut</div></Link>
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center bd-highlight mb-1">
              <Link to='/pineapple'><div className="p-2 bd-highlight flexMain"><img src={pineapple} className="img-fluid flex1 " alt="err" /></div></Link>
              <Link to='/pineapple' onClick={handleClose}><div className="p-2 mx-2 bd-highlight flex2">Pineapple</div></Link>
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center bd-highlight mb-1 me-0">
              <Link to="/Alphansomango"><div className="p-2 bd-highlight flexMain"><img src={Mango} className="img-fluid flex1 " alt="err" /></div></Link>
              <Link to="/Alphansomango" onClick={handleClose}><div className="p-2 mx-2 bd-highlight flex2">Mango</div></Link>
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center bd-highlight mb-1 me-0">
              <Link to="/Redonion"><div className="p-2 bd-highlight flexMain"><img src={onion} className="img-fluid flex1 " alt="err" /></div></Link>
              <Link to="/Redonion" onClick={handleClose}><div className="p-2 mx-2 bd-highlight flex2">Onion</div></Link>
            </div>
          </div>


            <div className="d-flex flex-column gap-4">
              <h5>Processed Food</h5>
              <div className="d-flex flex-row justify-content-start align-items-center bd-highlight mb-1">
                <Link to="/sugar"><div className=" bd-highlight  flexMain"><img src={sugar} className="img-fluid flex1 " alt="err" /></div></Link>
                <Link to="/sugar" onClick={handleClose}><div className="p-2 mx-2 bd-highlight flex2">Sugar</div></Link>
              </div>
              <div className="d-flex flex-row justify-content-start align-items-center bd-highlight mb-1">
                <Link to="/maida"><div className="p-2 bd-highlight flexMain"><img src={maida} className="img-fluid flex1 " alt="err" /></div></Link>
                <Link to="/maida" onClick={handleClose}><div className="p-2 mx-2 bd-highlight flex2">Maida</div></Link>
              </div>
              <div className="d-flex flex-row justify-content-start align-items-center bd-highlight mb-1">
                <Link to="/jaggery"><div className="p-2 bd-highlight flexMain"><img src={gull} className="img-fluid flex1 " alt="err" /></div></Link>
                <Link to="/jaggery" onClick={handleClose}><div className="p-2 mx-2 bd-highlight flex2">Gull</div></Link>
              </div>
              <div className="d-flex flex-row justify-content-start align-items-center bd-highlight mb-1">
                <Link to="/turmeric"><div className="p-2 bd-highlight flexMain"><img src={turmeric} className="img-fluid flex1 " alt="err" /></div></Link>
                <Link to="/turmeric" onClick={handleClose}><div className="p-2 mx-2 bd-highlight flex2">Turmeric</div></Link>
              </div>
              <h5>Beverages</h5>
              <div className="d-flex flex-row justify-content-start align-items-center bd-highlight mb-1">
                <Link to="/water"><div className="p-2 bd-highlight  flexMain"><img src={water} className="img-fluid flex1 " alt="err" /></div></Link>
                <Link to="/water" onChange={handleClose}><div className="p-2 mx-2 bd-highlight flex2">Mineral Water</div></Link>
              </div>
            </div>
            <div className="d-flex flex-column gap-4 beverages">
             
            
            </div>
          </Modal.Body>

        </Modal>

      </div>
    </>
  );
};

export default Navbar;
