import React from "react";
import { FiberManualRecord } from "@material-ui/icons";
import { Row, Col, Carousel } from "react-bootstrap";
import services1 from "../../img/sell.jfif";
import sell2 from "../../img/sell2.jpeg";
import sell3 from "../../img/sell3.jpeg";
import sell4 from "../../img/grow1.jpg";
import sell5 from "../../img/sell5.jpeg";
import sell6 from "../../img/sell6.JPG";
import six from "../../img/six.jpg";

import "boxicons";
import { FaCanadianMapleLeaf } from "react-icons/fa";
import { GiCurledLeaf } from "react-icons/gi";
const About = () => {
    return (
        <div id="aboutCarousel" className="container  mb-5 pb-5">
            <Row className="m- p-0 m-md-2 mt-5 mb-5">
                <Col md="7" className="mb-5 my-5 px-5">
                    <Carousel fade={true} pause={false}>
                        <Carousel.Item interval={5000}>
                            <div>
                                <img
                                    className="d-block w-100"
                                    src={services1}
                                    alt="second slide"
                                />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item interval={5000}>
                            <div>
                                <img
                                    className="d-block w-100"
                                    src={sell2}
                                    alt="second slide"
                                />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item interval={5000}>
                            <div>
                                <img
                                    className="d-block w-100"
                                    src={sell3}
                                    alt="second slide"
                                />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item interval={5000}>
                            <div>
                                <img
                                    className="d-block w-100"
                                    src={sell4}
                                    alt="second slide"
                                />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item interval={5000}>
                            <div>
                                <img
                                    className="d-block w-100"
                                    src={six}
                                    alt="second slide"
                                />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item interval={5000}>
                            <div>
                                <img
                                    className="d-block w-100"
                                    src={sell6}
                                    alt="second slide"
                                />
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </Col>
                <Col md="4" className="my-5 px-5">
                    <h2>Our Key Services</h2>
                    <hr className="" />
                    <div>
                        <span className="d-flex">
                            <FaCanadianMapleLeaf size={25} className="leaf" />{" "}
                            <h5 className="mb-3 ms-3 ">Manufacturing</h5>
                        </span>
                        <hr className="hor" />
                        <span className="d-flex">
                            <GiCurledLeaf size={25} className="leaf" />{" "}
                            <h5 className="mb-3 ms-3 ">Distribution</h5>
                        </span>
                        <hr className="hor" />
                        <span className="d-flex">
                            <FaCanadianMapleLeaf size={25} className="leaf" />{" "}
                            <h5 className="mb-3 ms-3 ">Trading</h5>
                        </span>
                        <hr className="hor" />
                        <span className="d-flex">
                            <GiCurledLeaf size={25} className="leaf" />{" "}
                            <h5 className="mb-3 ms-3 ">Supplier</h5>
                        </span>
                        <hr className="hor" />
                        <span className="d-flex">
                            <FaCanadianMapleLeaf size={25} className="leaf" />{" "}
                            <h5 className="mb-3 ms-3 ">On Field Service</h5>
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default About;
